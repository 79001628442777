import { MegaMenuProps, MenuDataProps } from "../../../types";
import MainLink from "./MainLink"
import { menuData } from "./menuData"





const MegaMenu = ({ handleToggle, handleToggleSubmenu, clicked, setIsDrawerOpen,clickedSubMenu }:MegaMenuProps) => {
    return (
        <nav className="h-full   ">
          <ul className=" flex flex-col lg:flex-row h-full w-full items-start lg:items-center justify-center  ">
            {menuData.map(({label, href, children}:MenuDataProps, index) => (
                <MainLink
                key={index}
                to={href}
                label={label}
                childrenMenu={children as unknown as MenuDataProps}
                setIsDrawerOpen={setIsDrawerOpen}
                onToggle={() => handleToggle && handleToggle(index)}
                onToggleSubmenu={handleToggleSubmenu}
                active={clicked === index}
                clickedSubMenu={clickedSubMenu}
              />                   
            ))}
          </ul>
        </nav>
    )
}

export default MegaMenu

