import { useEffect, useState } from 'react';
import {
	useNavigate,
	Link,
	Form,
	useLoaderData,
	LoaderFunctionArgs,
} from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Button from '../../../components/shared/Button';

import {
	extractMultipleErrors,
	hasNestedObjects,
	showToast,
} from '../../../utils';
import ForumMembersStore from '../services/forumMembers.store';
import OrganizationsStore from '../../organizations/services/organizations.store';

import { ActionResponse, DropDownItemInterface } from '../../../types';

/* Fields Validation */
import {
	REQUIRED_VALIDATION_RULE,
	EMAIL_VALIDATION_RULES,
	PASSWORD_VALIDATION_RULES,
	ROLE_OPTIONS,
	SUBMITTING_TEXT,
	LOADING_TEXT,
	CANCEL,
} from '../../../constants';
import RolesStore from '../../roles/services/roles.store';

const forumMembersStore: ForumMembersStore = new ForumMembersStore();
const organizationsStore: OrganizationsStore = new OrganizationsStore();
const rolesStore: RolesStore = new RolesStore();

const ForumMemberForm = () => {
	const navigate = useNavigate();

	// Use the `useGetRegions` hook from the regions store to fetch regions data
	const [initOrganizationPage, setInitOrganizationPage] = useState(1);
	const {
		data: organizationsQueryData,
		isLoading: isLoadingOrganizations,
		isFetching: isFetchingOrganizations,
	} = organizationsStore.useGetOrganizations(initOrganizationPage, '');
	const [availableOrganizations, setAvailableOrganizations] = useState<
		DropDownItemInterface[]
	>([]);

	// Use the `useGetRoles` hook from the roles store to fetch roles data
	const [initRolesPage, setInitRolesPage] = useState(1);
	const {
		data: rolesQueryData,
		isLoading: isLoadingRoles,
		isFetching: isFetchingRoles,
	} = rolesStore.useGetRoles(initRolesPage);
	const [availableRoles, setAvailableRoles] = useState<
		DropDownItemInterface[]
	>([]);

	// Effect to fetch all pages of organizations data to be displayed as dropdown options
	useEffect(() => {
		if (
			!organizationsQueryData ||
			isLoadingOrganizations ||
			isFetchingOrganizations
		)
			return;

		const organizations =
			organizationsQueryData?._embedded?.organizations || [];
		const currentPage = organizationsQueryData?._page || 1;
		const organizationsPageCount = organizationsQueryData?._page_count || 1;

		// Extract and map organizations
		const extractedOrganizations = organizations.map((org: any) => ({
			id: org.id,
			label: org.name,
		}));

		// Update available organizations with new data
		setAvailableOrganizations((prevOrgs) => [
			...prevOrgs,
			...extractedOrganizations,
		]);

		// Update the page to fetch next set of organizations
		if (currentPage < organizationsPageCount) {
			setInitOrganizationPage(currentPage + 1);
		}
	}, [
		organizationsQueryData,
		isLoadingOrganizations,
		isFetchingOrganizations,
	]);

	// Effect to fetch all pages of regions data to be displayed as dropdown options
	useEffect(() => {
		if (!rolesQueryData || isLoadingRoles || isFetchingRoles) return;

		const roles = rolesQueryData?._embedded?.roles || [];
		const currentPage = rolesQueryData?._page || 1;
		const rolesPageCount = rolesQueryData?._page_count || 1;

		// Extract and map regions
		const extractedRoles = roles.map((role: any) => ({
			id: role.id,
			label: role.name,
		}));

		// Update available regions with new data
		setAvailableRoles((prevRoles) => [...prevRoles, ...extractedRoles]);

		// Update the page to fetch next set of regions
		if (currentPage < rolesPageCount) {
			setInitRolesPage(currentPage + 1);
		}
	}, [rolesQueryData, isLoadingRoles, isFetchingRoles]);

	// Get loader data to populate 'edit' form
	const loaderData = useLoaderData() as ActionResponse<any> | null;
	let editFormData: any | undefined = undefined;

	if (loaderData && loaderData.success) {
		editFormData = loaderData?.data;
	}

	// React Hook Form methods and state initialization
	const methods = useForm<any>({
		defaultValues: {
			email: editFormData?.email || '',
			organizationId: editFormData?.organization_id || '',
			roleIds: editFormData?.roles || [],
			firstName: editFormData?.first_name || '',
			lastName: editFormData?.last_name || '',
			phone: editFormData?.phone || '',
			mobilePhone: editFormData?.mobile_phone || '',
			fax: '',
			organizationFunction: editFormData?.organization_function || '',
		},
	});

	const {
		handleSubmit,
		setError,
		formState: { isSubmitting },
	} = methods;

	/**
	 * Handles form submission.
	 */
	const onSubmit: SubmitHandler<FieldValues> = async ({
		roleIds,
		...data
	}) => {
		const response =
			editFormData && editFormData?.id
				? await forumMembersStore.updateForumMember(
						editFormData.id,
						data
				  )
				: await forumMembersStore.addForumMember(data);

				const { success, message } = response
		
		
		if (success) {
			const id = !editFormData?.id ? response.data.id : editFormData.id;

			// Here sent request to setForumMemberesRole
			const { success: rolesSucces, message: rolesMessage } =
				await forumMembersStore.setForumMemberRoles(id, roleIds);

			if (rolesSucces) {
				showToast(message, success);
				navigate('..', { replace: true });
			} else {
				if (hasNestedObjects(rolesMessage)) {
					// Set input fields errors
					const errorMessages = extractMultipleErrors(rolesMessage);

					for (const key in errorMessages) {
						setError(key as any, {
							type: 'backend',
							message: errorMessages[key],
						});
					}
				} else {
					// Show toast error
					showToast(rolesMessage, rolesSucces);
				}
			}
		} else {
			if (hasNestedObjects(message)) {
				// Set input fields errors
				const errorMessages = extractMultipleErrors(message);

				for (const key in errorMessages) {
					setError(key as any, {
						type: 'backend',
						message: errorMessages[key],
					});
				}
			} else {
				// Show toast error
				showToast(message, success, true);
			}
		}

		//reset({}, { keepValues: true });
	};

	return (
		<>
			<FormProvider {...methods}>
				<Form
					method='post'
					className='pks-layout-col-md'
					onSubmit={handleSubmit(onSubmit)}>
					<div className='pks-layout-col'>
						{/* Institution DropDown*/}
						{availableOrganizations && (
							<DropDownSelect
								id='organizationId'
								name='organizationId'
								label='Organizacija *'
								placeholder='Izaberi Organizaciju'
								options={availableOrganizations}
								validationRules={REQUIRED_VALIDATION_RULE}
							/>
						)}
						<div className='flex flex-col gap-2 sm:flex-row'>
							{/* Name Input */}
							<div className='flex-1'>
								<Input
									type='text'
									id='firstName'
									name='firstName'
									label='Ime *'
									placeholder='Unesi ime'
									autoComplete='off'
									validationRules={REQUIRED_VALIDATION_RULE}
								/>
							</div>
							<div className='flex-1'>
								{/* Last Name Input */}
								<Input
									type='text'
									id='lastName'
									name='lastName'
									label='Prezime *'
									placeholder='Unesi prezime'
									autoComplete='off'
									validationRules={REQUIRED_VALIDATION_RULE}
								/>
							</div>
						</div>
						{/* Email Input */}
						<Input
							type='text'
							id='email'
							name='email'
							label='Email *'
							placeholder='Unesi email'
							autoComplete='off'
							validationRules={EMAIL_VALIDATION_RULES}
						/>
						{/* <Input
							type='password'
							id='password'
							name='password'
							label='Lozinka *'
							placeholder='Unesi lozinku'
							autoComplete='off'
							validationRules={
								!editFormData
									? PASSWORD_VALIDATION_RULES
									: undefined
							}
						/> */}
						<DropDownSelect
							id='roleIds'
							name='roleIds'
							label='Uloga*'
							placeholder='Izaberi ulogu'
							options={availableRoles}
							isLoading={isLoadingRoles || isFetchingRoles}
							multiple
						/>
						<Input
							type='number'
							id='phone'
							name='phone'
							label='Telefon'
							placeholder='Unesi telefon'
							autoComplete='off'
						/>
						<Input
							type='number'
							id='mobilePhone'
							name='mobilePhone'
							label='Mobilni telefon*'
							placeholder='Unesi mobilni telefon'
							autoComplete='off'
							validationRules={REQUIRED_VALIDATION_RULE}
						/>
						<Input
							type='faks'
							id='fax'
							name='fax'
							label='Faks'
							placeholder='Unesi faks'
							autoComplete='off'
						/>
						<Input
							type='text'
							id='function'
							name='organizationFunction'
							label='Funkcija*'
							placeholder='Unesi funkciju'
							autoComplete='off'
							validationRules={REQUIRED_VALIDATION_RULE}
						/>
					</div>
					<div className='w-full inline-flex flex-wrap gap-4'>
						<Button className='flex-grow' disabled={isSubmitting}>
							{isLoadingOrganizations ||
							isLoadingRoles ||
							isFetchingOrganizations ||
							isFetchingRoles
								? LOADING_TEXT
								: isSubmitting
								? SUBMITTING_TEXT
								: editFormData
								? 'Izmeni člana'
								: 'Dodaj člana'}
						</Button>
						<Link
							to='..'
							className='flex-auto sm:flex-none focus:outline-none group'>
							<Button
								type='button'
								variant='secondary'
								wide
								tabIndex={-1}>
																	{CANCEL}
							</Button>
						</Link>
					</div>
				</Form>
			</FormProvider>
		</>
	);
};

export default ForumMemberForm;

export async function loader({
	params,
}: LoaderFunctionArgs): Promise<ActionResponse<any> | null> {
	const slug = Number(params.slug);

	if (slug) {
		const data = await forumMembersStore.preloadForumMember(slug);
		const roles = await forumMembersStore.getForumMemberRole(slug);
		
		if (data && data.success) {
			data.data.roles = [...roles.data.roles];  
		}

		return data;
	}

	return null;
}