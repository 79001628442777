import { useState, ImgHTMLAttributes } from 'react';

interface LazyImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

const LazyImage: React.FC<LazyImageProps> = ({
	src,
	alt,
	loading = 'lazy',
	className,
	...rest
}) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleImageLoaded = () => {
		setImageLoaded(true);
	};

	return (
		<img
			alt={alt}
			src={src}
			loading={loading}
			className={`${className} ${
				imageLoaded ? 'opacity-100' : 'opacity-0'
			}`}
			{...rest}
			onLoad={handleImageLoaded}
		/>
	);
};

export default LazyImage;
