import { EMAIL_REGEX, PASSWORD_REGEX } from './regex';

const REQUIRED_TEXT = "Value is required and can't be empty";

export const REQUIRED_VALIDATION_RULE = {
	required: REQUIRED_TEXT,
};

export const NUMBERS_VALIDATION_RULES = {
	pattern: {
		value: /^[0-9]$/,
		message: 'Enter numbers',
	},
};

export const LETTERS_VALIDATION_RULES = {
	pattern: {
		value: /^([A-Za-zČĆŽŠĐLjNjDžčćžšđljnjdžЉЊЏЂЖША-Яа-яёЁ0-9 ]*)$/,
		message: 'Enter letters, dashes, and spaces.',
	},
};

export const LETTERS_REQUIRED_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^([A-Za-zČĆŽŠĐLjNjDžčćžšđljnjdžЉЊЏЂЖША-Яа-яёЁ0-9 ]*)$/,
		message: 'Enter only letters, dashes, or spaces.',
	},
};

export const EMAIL_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: EMAIL_REGEX,
		message: 'Email is not valid',
	},
};

export const PASSWORD_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	minLength: {
		value: 12,
		message: 'Password must be at least 12 characters long',
	},
	maxLength: {
		value: 50,
		message: 'Password cannot be longer than 50 characters',
	},
	pattern: {
		value: PASSWORD_REGEX,
		message:
			'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
	},
};

export const BIN_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9]{16}$/,
		message: 'Field must have exactly 16 digits',
	},
};

export const ACQUIRER_ID_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	minLength: { value: 3, message: 'Field must have at least 3 characters' },
	maxLength: { value: 10, message: 'Field can have at most 10 characters' },
	pattern: { value: /^[A-Za-z]+$/, message: 'Only letters are allowed' },
};

export const MID_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9]{8,15}$/,
		message: 'Field must have between 8 and 15 digits',
	},
};

export const TID_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9]{8,10}$/,
		message: 'Field must have between 8 and 10 digits',
	},
};

export const POSTAL_CODE_VALIDATION_RULES = {
	pattern: {
		value: /^\d{5}(-\d{4})?$/,
		message: 'Enter a valid postal code.',
	},
};

export const DOMAIN_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^(https?:\/\/)?([a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,})$/,
		message: 'Enter a valid domain.',
	},
};

export const PHONE_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^(\+?\d{8,15}|(0|00)?\d{8,15})$/,
		message: 'Enter a valid phone number',
	},
};

export const MOBILE_PHONE_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^\+?\d{8,15}$/,
		message: 'Enter a valid mobile phone number',
	},
};

export const FAX_VALIDATION_RULES = {
	required: REQUIRED_TEXT,
	pattern: {
		value: /^[0-9+()-]{7,20}$/,
		message: 'Enter a valid fax number.',
	},
};
