import { useNavigate, Form, Link } from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import Button from '../../../components/shared/Button';
import Input from '../../../components/shared/Input';

import AuthStore from '../services/auth.store';
import {
	extractMultipleErrors,
	hasNestedObjects,
	showToast,
} from '../../../utils';

import {
	EMAIL_VALIDATION_RULES,
	LOGIN_EMAIL_VERIFICATION_PAGE_LINK,
	REQUIRED_VALIDATION_RULE,
	SUBMITTING_TEXT,
	FORGOT_PASSWORD_PATH,
	/* PASSWORD_VALIDATION_RULES, */
} from '../../../constants';

const authStore: AuthStore = new AuthStore();

/**
 * LoginForm component for handling user authentication.
 *
 * @component
 * @returns {JSX.Element} The rendered Login form.
 */
const LoginForm = (): JSX.Element => {
	const navigate = useNavigate();

	// Initialize React Hook Form methods with default values
	const methods = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
		setError,
	} = methods;
	/**
	 * Handles form submission.
	 *
	 * @param {FieldValues} data - The form data including username and password.
	 * @returns {Promise<void>} A promise that resolves when the submission is complete.
	 */
	const onSubmit: SubmitHandler<FieldValues> = async (
		data: FieldValues
	): Promise<void> => {
		const { username: email, password } = data;

		const response = await authStore.authenticateUser(email, password);
		const { success, message } = response;

		if (success) {
			const resData = response.data;

			if (!resData) {
				showToast(message, !success);
				return;
			}

			// Store the authentication object in local storage
			const auth = {
				userId: resData.user_id,
				token: resData.access_token,
			};
			localStorage.setItem('auth', JSON.stringify(auth));

			// Show toast success
			showToast(message, true);

			// Navigate to the email verification page to complete MFA setup
			navigate(LOGIN_EMAIL_VERIFICATION_PAGE_LINK);
		} else {
			if (hasNestedObjects(message)) {
				// Set input fields errors
				const errorMessages = extractMultipleErrors(message);

				for (const key in errorMessages) {
					setError(key as 'username' | 'password', {
						type: 'backend',
						message: errorMessages[key],
					});
				}
			} else {
				// Show toast error
				showToast(message, success, true);
			}
		}
	};

	return (
		<>
			{/* Form wrapped in FormProvider to use React Hook Form context */}
			<FormProvider {...methods}>
				<Form
					method='post'
					className='pks-layout-col-xl w-full'
					onSubmit={handleSubmit(onSubmit)}>
					{/* Form inputs and forgot password link */}
					<div className='pks-layout-col'>
						{/* Email Input Field */}
						<Input
							type='text'
							id='username'
							name='username'
							label='Email *'
							placeholder='Unesi email'
							autoFocus
							autoComplete='off'
							validationRules={EMAIL_VALIDATION_RULES}
						/>
						{/* Password Input Field */}
						<Input
							type='password'
							id='password'
							name='password'
							label='Lozinka *'
							placeholder='Unesi lozinku'
							autoComplete='current-password'
							/* validationRules={PASSWORD_VALIDATION_RULES} */
							validationRules={REQUIRED_VALIDATION_RULE}
						/>
						{/* Forgot Password Link */}
						<Link to={FORGOT_PASSWORD_PATH}>
							Zaboravljena lozinka?
						</Link>
					</div>
					{/* Submit Button */}
					<Button type='submit' wide disabled={isSubmitting}>
						{isSubmitting ? SUBMITTING_TEXT : 'Uloguj se'}
					</Button>
				</Form>
			</FormProvider>
			{/* Placeholder for additional authentication methods (e.g., Google, Microsoft) */}
			{/* <div className='pt-8 border-t border-t-secondary-300 text-center'>
				Google and Microsoft Auth
			</div> */}
		</>
	);
};

export default LoginForm;
