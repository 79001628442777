import { Link, LinkProps } from 'react-router-dom';

interface SeeMoreLinkProps {
	url: LinkProps['to'];
	label?: string;
	blank?: boolean;
}

const SeeMoreLink: React.FC<SeeMoreLinkProps> = ({
	url,
	label = 'Placeholder Text',
	blank = false,
}) => {
	return (
		<div className='text-right'>
			<Link
				to={url}
				target={blank ? '_blank' : undefined}
				className='text-primary-300 font-bold underline hover:text-secondary'>
				{label}
			</Link>
		</div>
	);
};

export default SeeMoreLink;
