import { useLocation } from 'react-router-dom';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import OrganizationForm from '../../modules/organizations/UI/OrganizationForm';

function OrganizationFormPage() {
	//TODO: Find better solution for this
	const location = useLocation();
	const path: string = location.pathname;
	const pathSegments: string[] = path.split('/');
	const organizationSegment: string | undefined = pathSegments[3]; 
	const isNumericId: boolean = !!organizationSegment && !isNaN(Number(organizationSegment));
	const pageTitle: string = isNumericId ? 'Izmena organizacije' : 'Unos organizacije';

	return (
		<>
			<Meta title={pageTitle} />
			<PageLayout className='max-w-[500px]' title={pageTitle}>
				<OrganizationForm />
			</PageLayout>
		</>
	);
}

export default OrganizationFormPage;
