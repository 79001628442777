export const NUMBER_REGEX = /^[0-9]+$/;

export const ALPHANUMERIC_SINGLE_REGEX = /^[a-zA-Z0-9]$/;

export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/;

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const PASSWORD_REGEX =
	/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.* ).{12,24}$/;
