import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import FraudCaseForm from '../../modules/fraudCases/UI/FraudCaseForm';

const PAGE_TITLE = 'Unos fraud case-a';

function FraudCaseFormPage() {
	return (
		<>
			<Meta title={PAGE_TITLE} />
			<PageLayout title={PAGE_TITLE}>
				<FraudCaseForm />
			</PageLayout>
		</>
	);
}

export default FraudCaseFormPage;
