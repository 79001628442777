import { Link } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import Button from '../../components/shared/Button';
import ForumMembers from '../../modules/forumMembers/UI';

import { FORUM_MEMBER_ADD_PATH } from '../../constants';

function ForumMembersPage() {
	const pageTitle = 'Članovi foruma';
	return (
		<>
			<Meta title={pageTitle} />
			<PageLayout>
				<PageTitleWithActions title={pageTitle}>
					<Link
						to={FORUM_MEMBER_ADD_PATH}
						className='focus:outline-none group'>
						<Button tabIndex={-1}>Dodaj člana</Button>
					</Link>
				</PageTitleWithActions>
				<ForumMembers />
			</PageLayout>
		</>
	);
}

export default ForumMembersPage;
