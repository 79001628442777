import {
	keepPreviousData,
	QueryKey,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import queryClient from '../../../config/tanstackQueryConfig';
import { handleErrors } from '../../../utils';
import OrganizationsApi from './organizations.api';

import { ActionResponse } from '../../../types';
import {
	OrganizationFormInterface,
	OrganizationInterface,
	OrganizationsResponseDataInterface,
} from './organizations.types';

const QUERY_KEY = 'organizations';

const api: OrganizationsApi = new OrganizationsApi();

class OrganizationsStore {
	preloadOrganizations = async (page?: number, searchTerm?: string) => {
		return await queryClient.fetchQuery(
			organizationsQueryOptions(page, searchTerm)
		);
	};

	useGetOrganizations = (page?: number, searchTerm?: string) => {
		return useQuery(organizationsQueryOptions(page, searchTerm));
	};
	
	getOrganization = async (
		id: number
	): Promise<ActionResponse<OrganizationInterface>> => {
		try {
			const res = await api.getOrganization(id);
			const resData = res.data;
			return {
				success: true,
				message: resData?.message || 'Organization successfully added!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	preloadOrganization = this.getOrganization;

	addOrganization = async (
		data: OrganizationFormInterface
	): Promise<ActionResponse<OrganizationInterface>> => {
		try {
			const res = await api.addOrganization(data);
			const resData = res.data;
			queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
			return {
				success: true,
				message: resData?.message || 'Organization successfully added!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	// Update organization
	updateOrganization = async (
		id: number,
		data: OrganizationFormInterface
	): Promise<ActionResponse<OrganizationInterface>> => {
		try {
			const res = await api.updateOrganization(id, data);
			const resData = res.data;
			queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
			return {
				success: true,
				message:
					resData?.message || 'Organization successfully edited!',
				data: resData,
			};
		} catch (error) {
			const { message, success } = handleErrors(error);
			return { message, success };
		}
	};

	getOrganizationLogo = async (id: number) => {
		try {
			const res: any = await api.getOrganizationLogo(id)
			const imageBlob = new Blob([res.data], { type: res.headers['content-type'] })
			const url = URL.createObjectURL(imageBlob)
			return url
		} catch (error) {
			console.log("error")
		}
	}
}

/**
 * Returns query options for fetching / prefetching organizations.
 */
export const organizationsQueryOptions = (
	page?: number,
	searchTerm?: string
): UseQueryOptions<OrganizationsResponseDataInterface, Error> => ({
	queryKey: ['organizations', { page, searchTerm }] as QueryKey,
	queryFn: async (): Promise<OrganizationsResponseDataInterface> => {
		const response: AxiosResponse<OrganizationsResponseDataInterface> =
			await api.getOrganizations(page, searchTerm);
		return response.data;
	},
	placeholderData: keepPreviousData,
});

export default OrganizationsStore;
