import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './context/auth-context';
import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './config/tanstackQueryConfig';
import { ModalProvider } from './context/modal-context';

interface ProvidersProps {
  children: ReactNode;
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ModalProvider>{children}</ModalProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

export default Providers;
