import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import Roles from '../../modules/roles/UI';

const RolesPage = () => {
	const pageTitle = 'Role';
	return (
		<>
			<Meta title={pageTitle} />
			<PageLayout>
				<Roles />
			</PageLayout>
		</>
	);
};

export default RolesPage;
