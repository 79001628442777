// src/components/hocs/withRole.tsx
import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/auth-context';
import { RoleEnum } from '../../types';
import { LOGIN_EMAIL_VERIFICATION_PAGE_LINK } from '../../constants';

/**
 * A higher-order component (HOC) that wraps another component and enforces role-based access control.
 *
 * @param Component - The component to be wrapped.
 * @param requiredRoles - An array of roles that are allowed to access the component.
 *
 * @returns A new component that checks user roles and redirects if necessary.
 */
const withRole = <P extends object>(
	Component: ComponentType<P>,
	requiredRoles: RoleEnum[]
): ComponentType<P> => {
	return (props: P) => {
		// Get authentication and role data from the context
		const { isAuth } = useAuth();

		// If the user is not authenticated, navigate to the login page
		if (!isAuth) {
			return <Navigate to={LOGIN_EMAIL_VERIFICATION_PAGE_LINK} replace />;
		}

		// Check if the user has at least one of the required roles
		/* if (!requiredRoles.some((role) => roles?.includes(role))) {
			// If not, navigate to the unauthorized page
			return <Navigate to='/unauthorized' replace />;
		} */

		// If the user is authenticated and has the necessary roles, render the wrapped component
		return <Component {...props} />;
	};
};

export default withRole;
