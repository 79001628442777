/* Link */
interface LinkWithLabelInterface {
	url: string;
	label: string;
}

// Contact
export enum ContactIdEnum {
	Address = 'address',
	Phone = 'phone',
	Email = 'email',
}

export interface ContactItemInterface extends LinkWithLabelInterface {
	id: ContactIdEnum;
	external?: boolean;
	ariaLabel?: string;
}

// Social Media
export enum SocialMediaEnum {
	Facebook = 'facebook',
	Twitter = 'twitter',
	LinkedIn = 'linkedin',
}

export interface SocialMediaItemInterface extends LinkWithLabelInterface {
	id: SocialMediaEnum;
}
