import Icon from '../Icon';

function TransitionLoader() {
	return (
		<div className='fixed z-10 h-screen top-0 left-0 w-full flex items-center justify-center bg-white opacity-75'>
			<div>
				<Icon name='pageSpinner' className='flex justify-center' />
				<p className='font-bold h2'>Učitavanje...</p>
			</div>
		</div>
	);
}

export default TransitionLoader;
