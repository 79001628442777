import {
	keepPreviousData,
	UseQueryOptions,
	UseQueryResult,
	useQuery,
} from '@tanstack/react-query';
import { fetchLatestNews, fetchNews } from './index.api';
import { NewsArticleInterface, NewsPaginationInterface } from './index.types';

/**
 * Keys used for query identification in TanStack Query.
 */
export const QUERY_KEYS = {
	LATEST_NEWS: 'latest-news',
	NEWS: 'news',
};

/**
 * Function that returns query options for fetching latest news data.
 *
 * @returns {UseQueryOptions<NewsArticleInterface[], Error>}
 * Options for the `useQuery` hook to fetch latest news data.
 */
export const getLatestNewsQueryOptions = (): UseQueryOptions<
	NewsArticleInterface[],
	Error
> => ({
	queryKey: [QUERY_KEYS.LATEST_NEWS],
	queryFn: fetchLatestNews,
});

/**
 * Custom hooks to fetch data.
 * Utilize TanStack Query's `useQuery` to handle fetching, caching, and state management
 */

/**
 * Custom hook to fetch latest news.
 *
 * @returns {UseQueryResult<NewsArticleInterface[], Error>} - The result of the query including data, error, and loading state.
 */
export function useFetchLatestNews(): UseQueryResult<
	NewsArticleInterface[],
	Error
> {
	return useQuery(getLatestNewsQueryOptions());
}

/**
 * Function that returns query options for fetching news data.
 *
 * @returns {UseQueryOptions<NewsPaginationInterface, Error>}
 * Options for the `useQuery` hook to fetch news data.
 */
export const getNewsQueryOptions = (
	page: number,
	limit: number
): UseQueryOptions<NewsPaginationInterface, Error> => ({
	queryKey: [QUERY_KEYS.NEWS, { page, limit }],
	queryFn: () => fetchNews(page, limit),
	placeholderData: keepPreviousData,
});

/**
 * Custom hook that fetches paginated news using TanStack Query.
 *
 * @param {number} page - The current page number for pagination.
 * @param {number} limit - The number of items to be retrieved per page.
 *
 * @returns {UseQueryResult<NewsPaginationInterface, Error>}
 * An object containing the result of the query, including:
 *   - `data`: The fetched data, which conforms to `NewsPaginationInterface`.
 *   - `error`: Any error encountered during the query.
 *   - `isLoading`: A boolean indicating the loading state of the query.
 *   - `isFetching`: A boolean indicating if new data is being fetched in the background.
 *   - `isSuccess`: A boolean indicating whether the query was successful.
 */
export function useFetchNews(
	page: number,
	limit: number
): UseQueryResult<NewsPaginationInterface, Error> {
	return useQuery(getNewsQueryOptions(page, limit));
}
