import { useParams } from 'react-router-dom';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import ForumMemberForm from '../../modules/forumMembers/UI/ForumMembersForm';

const ForumMemberFormPage = () => {
	const { slug } = useParams();
	const pageTitle = slug ? 'Izmeni člana' : 'Dodaj člana';
	return (
		<>
			<Meta title={pageTitle} />
			<PageLayout className='max-w-[500px]' title={pageTitle}>
				<ForumMemberForm />
			</PageLayout>
		</>
	);
};

export default ForumMemberFormPage;
