import { Link, LoaderFunctionArgs, useSearchParams } from 'react-router-dom';

import PageTitleWithActions from '../../../layouts/PageLayout/TitleWithActions';
import Button from '../../../components/shared/Button';
import DropDownSelect from '../../../components/shared/DropdownSelect';
import Pagination from '../../../components/shared/Pagination';
import FraudCasesList from './FraudCasesList';

import { FRAUD_CASE_ADD_PATH } from '../../../constants';
import { DropDownItemInterface } from '../../../types';

import FraudCaseStore from '../services/fraudCases.store';
const fraudCasesStore: FraudCaseStore = new FraudCaseStore();

const FraudCases = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	// Parse URL search parameters
	const page = Number(searchParams.get('page')) || 1;
	const category = searchParams.get('category') || '';

	// Use the `useGetFraudCases` hook from the organizations store to fetch fraud cases data
	const { data: fraudCasesData } = fraudCasesStore.useGetFraudCases(
		page,
		category
	);

	const fraudCases = fraudCasesData?._embedded?.fraudcases || [];
	const currentPage = fraudCasesData?._page || 1;
	const totalPages = fraudCasesData?._page_count || 1;

	const { data: fraudCaseTypesData } =
		fraudCasesStore.useGetFraudCasesTypes();
	const fraudCaseOptions: DropDownItemInterface[] = [
		{
			id: 'all',
			label: 'Sve vrste prevara',
		},
		...(fraudCaseTypesData
			? Object.keys(fraudCaseTypesData).map((key) => ({
					id: key,
					label: fraudCaseTypesData[key],
			  }))
			: []),
	];

	const selectedOptionIndex = fraudCaseOptions.findIndex(
		(option) => option.id === category
	);

	/**
	 * Handles page changes in pagination.
	 * Updates the URL with the new page number.
	 *
	 * @param {number} newPage - The new page number to navigate to.
	 */
	const handlePageChange = (newPage: number) => {
		if (newPage.toString() !== currentPage) {
			searchParams.set('page', newPage.toString()); // Update the page parameter

			// Conditionally set category if it exists
			if (category) {
				searchParams.set('category', category);
			}

			// Update the search parameters in the URL
			setSearchParams(searchParams);
		}
	};

	// Handler for changing the category
	const handleCategoryChange = (selectedCategory: string) => {
		searchParams.set('page', '1');

		// Conditionally add or remove the category parameter
		if (selectedCategory === 'all') {
			searchParams.delete('category'); // Remove category if 'all'
		} else {
			searchParams.set('category', selectedCategory); // Set category if not 'all'
		}

		// Update the search parameters without navigating
		setSearchParams(searchParams);
	};

	return (
		<>
			{/* Page Title with Action */}
			<PageTitleWithActions title='Lista svih slučajeva'>
				<div className='inline-flex flex-wrap gap-4'>
					<Link
						to={FRAUD_CASE_ADD_PATH}
						className='flex-auto focus:outline-none group'>
						<Button wide tabIndex={-1}>
							Novi slučaj
						</Button>
					</Link>
					<Button isDisabled disabled className='flex-auto'>
						Generiši izveštaj
					</Button>
				</div>
			</PageTitleWithActions>

			{/* Fraud Cases List with Dropdown per Fraud Cases Types  and Pagination */}
			<div className='pks-layout-col-md'>
				<div className='max-w-xs'>
					<DropDownSelect
						label='Izaberi vrstu prevare'
						options={fraudCaseOptions}
						onChange={handleCategoryChange}
						selectedOptionIndex={
							selectedOptionIndex > -1 ? selectedOptionIndex : 0
						}
					/>
				</div>
				<FraudCasesList preview={false} data={fraudCases} />
				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
				/>
			</div>
		</>
	);
};

export default FraudCases;

export const loader = async ({ request }: LoaderFunctionArgs): Promise<any> => {
	// Extract page page and category from URL query parameters, falling back to default values if not present
	const { searchParams } = new URL(request.url);
	const page = Number(searchParams.get('page')) || 1;
	const category = searchParams.get('category') || '';

	// Use TanStack Query's fetchQuery to fetch the fraud cases
	await fraudCasesStore.preloadFraudCases(page, category);
	return null;
};
