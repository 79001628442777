import { RouteGroupInterface, SidebarEnum } from '../types';
import { extractPathsBySidebarValue } from '../utils';

/**
 * ROUTES
 */
export const ROUTES = {
	/* Dashboard */
	DASHBOARD: {
		ROOT: {
			absPath: '/',
			relPath: '/',
			sidebar: SidebarEnum.News,
		},

		/**
		 * Administration
		 */
		ADMINISTRATION: {
			ROOT: {
				absPath: '/administration',
				relPath: 'administration/',
				sidebar: undefined,
			},

			FORUM: {
				ROOT: {
					absPath: '/administration/',
					relPath: '/',
					sidebar: undefined, //SidebarEnum.Preview,
				},
				FORUM_MEMBER_ADD: {
					absPath: '/administration/add-member',
					relPath: 'add-member',
					sidebar: undefined,
				},
				FORUM_MEMBER_EDIT: {
					absPath: 'administration/:slug',
					relPath: ':slug',
					sidebar: undefined,
				},
			},

			// Roles
			ROLES: {
				ROOT: {
					absPath: '/administration/roles',
					relPath: 'roles',
					sidebar: undefined,
				},
			},

			// Organizations
			ORGANIZATIONS: {
				ROOT: {
					absPath: '/administration/organizations',
					relPath: 'organizations',
					sidebar: undefined,
				},
				ORGANIZATION_ADD: {
					absPath: '/administration/organizations/add-organization',
					relPath: 'add-organization',
					sidebar: undefined,
				},
				ORGANIZATION_EDIT: {
					absPath: '/administration/organizations/:slug',
					relPath: ':slug',
					sidebar: undefined,
				},
			},

			// Regions
			REGIONS: {
				ROOT: {
					absPath: '/administration/regions',
					relPath: 'regions',
					sidebar: undefined,
				},
			},

			// Forum Old
			FORUMS: {
				ROOT: {
					absPath: '/administration/forum',
					relPath: 'forum',
					sidebar: SidebarEnum.Preview,
				},
				FORUM_MEMBERS: {
					absPath: '/administration/forum/forum-members',
					relPath: 'forum-members',
					sidebar: SidebarEnum.Preview,
				},
				FORUM_COUNCIL: {
					absPath: '/administration/forum/forum-council',
					relPath: 'forum-council',
					sidebar: SidebarEnum.Preview,
				},
				FORUM_REPORTS: {
					absPath: '/administration/forum/forum-reports',
					relPath: 'forum-reports',
					sidebar: SidebarEnum.Preview,
				},
			},
		},

		/* Fraud Cases */
		FRAUD_CASES: {
			ROOT: {
				absPath: '/fraud-cases',
				relPath: 'fraud-cases/',
				sidebar: undefined, //SidebarEnum.News,
			},
			FRAUD_CASE_ADD: {
				absPath: '/fraud-cases/add-fraud-case',
				relPath: 'add-fraud-case',
				sidebar: undefined,
			},
			FRAUD_CASE_EDIT: {
				absPath: '/fraud-cases/edit/:slug',
				relPath: 'edit/:slug',
				sidebar: undefined,
			},
			FRAUD_CASE_DETAILS: {
				absPath: '/fraud-cases/:slug',
				relPath: ':slug',
				sidebar: undefined,
			},
		},

		/* Education */
		EDUCATION: {
			ROOT: {
				absPath: '/education',
				relPath: 'education/',
				sidebar: undefined,
			},
			ADD_DOCUMENT: {
				absPath: '/education/add-document',
				relPath: 'add-document',
				sidebar: undefined,
			},
			NEWS: {
				absPath: '/education/news',
				relPath: 'news',
				sidebar: SidebarEnum.PreviewWithStatistics,
			},
			NEWS_DETAILS: {
				absPath: '/education/news/:slug',
				relPath: ':slug',
				sidebar: SidebarEnum.Preview,
			},
		},

		/* Fraud Cases Old */
		FRAUD_CASES_OLD: {
			ROOT: {
				absPath: '/fraud-cases',
				relPath: 'fraud-cases/',
				sidebar: undefined, // SidebarEnum.News,
			},
			FRAUD_CASE_NEW: {
				absPath: '/fraud-cases/add',
				relPath: 'add',
				sidebar: undefined,
			},
			FRAUD_CASE_DETAILS: {
				absPath: '/fraud-cases/:slug',
				relPath: ':slug',
				sidebar: undefined,
			},
		},

		/* Forum */
		FORUM: {
			ROOT: {
				absPath: '/forum',
				relPath: 'forum',
				sidebar: undefined,
			},
		},

		/* Admin */
		ADMIN: {
			ROOT: {
				absPath: '/admin',
				relPath: 'admin/',
				sidebar: undefined,
			},
			USER_ENTRY: {
				absPath: '/admin/add-member',
				relPath: 'add-member',
				sidebar: undefined,
			},
		},

		/* Unauthorized */
		UNAUTHORIZED: {
			absPath: '/unauthorized',
			relPath: 'unauthorized',
			sidebar: SidebarEnum.Preview,
		},
	},

	RESET: {
		ROOT: {
			absPath: '/resetpassword',
			relPath: 'resetpassword',
			sidebar: undefined,
		},
	},

	/* Auth */
	LOGIN: {
		// Login
		ROOT: {
			absPath: '/login',
			relPath: 'login',
			sidebar: undefined,
		},

		// Email Verification
		EMAIL_VERIFICATION: {
			absPath: '/login/code-verification',
			relPath: 'code-verification',
			sidebar: undefined,
		},

		// Forgotten Password
		FORGOT_PASSWORD: {
			absPath: '/login/forgotpassword',
			relPath: 'forgotpassword',
			sidebar: undefined,
		},
	},

	/* Fallback route */
	NOT_FOUND: { absPath: '*', relPath: '*', sidebar: undefined },
};

/**
 * SIDEBAR
 */
// News
export const NEWS_SIDEBAR_PATHS = extractPathsBySidebarValue(
	ROUTES as RouteGroupInterface,
	SidebarEnum.News
);
// Preview
export const PREVIEW_SIDEBAR_PATHS = extractPathsBySidebarValue(
	ROUTES as RouteGroupInterface,
	SidebarEnum.Preview
);
// Preview With Statistics
export const PREVIEW_WITH_STATISTICS_SIDEBAR_PATHS = extractPathsBySidebarValue(
	ROUTES as RouteGroupInterface,
	SidebarEnum.PreviewWithStatistics
);

// Absolute paths extracted from ROUTES object
export const ORGANIZATION_PATH =
	ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ROOT.absPath;

export const ORGANIZATION_ADD_PATH =
	ROUTES.DASHBOARD.ADMINISTRATION.ORGANIZATIONS.ORGANIZATION_ADD.absPath;

export const REGIONS_PATH =
	ROUTES.DASHBOARD.ADMINISTRATION.REGIONS.ROOT.absPath;

export const FORUM_MEMBER_ADD_PATH =
	ROUTES.DASHBOARD.ADMINISTRATION.FORUM.FORUM_MEMBER_ADD.absPath;

export const FRAUD_CASE_PATH = ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath;

export const FRAUD_CASE_ADD_PATH =
	ROUTES.DASHBOARD.FRAUD_CASES.FRAUD_CASE_ADD.absPath;

export const FORGOT_PASSWORD_PATH = ROUTES.LOGIN.FORGOT_PASSWORD.absPath;
