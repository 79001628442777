import { LinkProps, NavLinkProps } from 'react-router-dom';

export function getNormalizedPathname(
	pathname: LinkProps['to'] | NavLinkProps['to']
): string {
	if (typeof pathname === 'string') {
		return decodeURIComponent(pathname);
	} else if (
		typeof pathname === 'object' &&
		pathname !== null &&
		'pathname' in pathname
	) {
		return decodeURIComponent((pathname as { pathname: string }).pathname);
	} else {
		throw new Error('Unsupported pathname type');
	}
}
