import { ReactNode } from 'react';

interface PageTitleWithActionsProps {
	title: string;
	children: ReactNode;
}

const PageTitleWithActions: React.FC<PageTitleWithActionsProps> = ({
	title,
	children,
}) => {
	return (
		<div className='flex flex-wrap justify-between items-center gap-4'>
			<h1>{title}</h1>
			<div>{children}</div>
		</div>
	);
};

export default PageTitleWithActions;
