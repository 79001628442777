import NewsPreview from '../../modules/news/UI/Preview';
import FreudCasesPreview from '../../modules/fraudCasesOld/UI/Preview';

const PreviewSidebar = () => {
	return (
		<div className='pks-layout-col-xl'>
			<NewsPreview />
			<FreudCasesPreview />
		</div>
	);
};

export default PreviewSidebar;
