import { useFetchStatistics } from '../services/index.queries';

import StatisticsList, { StatisticsSkeleton } from './List';
import InfoMessage from '../../../components/shared/InfoMessage';

/**
 * Interface defining the props for the Statistics component.
 * @interface
 * @property {string} [title] - The title of the statistics section.
 * @property {string} [description] - A description to provide context or additional information.
 * @property {boolean} [sidebar] - A flag indicating if the component is displayed in a sidebar context.
 */
interface StatisticsProps {
	title?: string;
	description?: string;
	sidebar?: boolean;
}

/**
 * Statistics Component
 *
 * This component is responsible for displaying a list of statistical data.
 * It handles data fetching, loading, and error states using a custom hook.
 *
 * @param {StatisticsProps} props - The props object for the component.
 * @returns {React.JSX.Element} The rendered Statistics component.
 */
const Statistics: React.FC<StatisticsProps> = ({
	title = 'Slučajevi u brojevima',
	description,
	sidebar = false,
}: StatisticsProps): React.JSX.Element => {
	// Fetch data
	const { data: statistics, isLoading, error } = useFetchStatistics();

	// Conditional content
	let content = null;
	if (isLoading) {
		content = <StatisticsSkeleton sidebar={sidebar} />;
	} else if (error || !statistics?.length) {
		const message = error
			? error.message
			: 'Trenutno nema dostupnih statističkih podataka';
		const icon = error ? 'danger' : 'info';
		content = <InfoMessage message={message} icon={icon} />;
	} else if (statistics?.length) {
		content = <StatisticsList sidebar={sidebar} data={statistics} />;
	}

	return (
		<div className='pks-layout-col-md'>
			{title && <h3 className='h2'>{title}</h3>}
			{description && <p>{description}</p>}
			{content}
		</div>
	);
};

export default Statistics;
