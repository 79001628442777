interface ListboxButtonProps {
	state?: 'error' | 'initial' | 'submitted';
	open?: boolean;
	autoFocus?: boolean;
	isLoading?: boolean;
}

export const getListboxButtonClassName = ({
	open,
	state,
	autoFocus,
}: ListboxButtonProps): string => {
	const className = ['pks-input', 'text-left'];

	const initialStateClassName = [
		'border-secondary-300',
		'hover:border-primary-300',
		'focus:border-primary-300-transparent',
		...(open === true || autoFocus
			? ['focus:shadow-[0_0_0_2px_var(--color-primary-300-transparent)]']
			: []),
	];

	const openClassName = {
		error: [
			'border-danger-transparent',
			'shadow-[0_0_0_2px_var(--color-danger-transparent)]',
		],
		submitted: [
			'border-success-transparent',
			'shadow-[0_0_0_2px_var(--color-success-transparent)]',
		],
		initial: [
			'border-primary-300-transparent',
			'shadow-[0_0_0_2px_var(--color-primary-300-transparent)]',
		],
	};

	const closeClassName = {
		error: ['border-danger'],
		submitted: ['border-success'],
		initial: initialStateClassName,
	};

	return [
		...className,
		...(open !== undefined
			? open || autoFocus
				? openClassName[state ?? 'initial']
				: closeClassName[state ?? 'initial']
			: []),
	].join(' ');
};

export const handleListboxButtonKeyDown = (
	e: React.KeyboardEvent<HTMLButtonElement>,
	ref: React.MutableRefObject<HTMLButtonElement | null>
): void => {
	if (e.key === 'Enter' || e.key === ' ') {
		e.preventDefault(); // Prevent scrolling when space is pressed
		ref?.current?.click(); // Trigger file input click
	}
};
