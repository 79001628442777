import { redirect, Link } from 'react-router-dom';

import Button from '../../components/shared/Button';

import { HOME_PAGE_LINK, LOGIN_PAGE_LINK } from '../../constants';
import { useAuth } from '../../context/auth-context';
import { useLogout } from '../../hooks/useLogout';

function LogoutPage() {
	const { user } = useAuth();
	const handleLogout = useLogout();

	return (
		<div className='text-center'>
			<div className='pks-card overflow-hidden inline-block rounded-lg'>
				<div className='pks-layout-col bg-white px-4 py-3'>
					<p className='font-bold'>
						Zdravo, {user?.name}!<br /> Da li ste sigurni da želite
						da se odjavite sa naloga?
					</p>
					<div>
						<div className='inline-flex flex-wrap gap-4'>
							<Button
								className='flex-auto'
								onClick={handleLogout}>
								Logout
							</Button>
							<Link
								className='flex-auto'
								to={HOME_PAGE_LINK}
								replace={true}>
								<Button className='w-full'>
									Početna stranica
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LogoutPage;

export function action() {
	return redirect(LOGIN_PAGE_LINK);
}
