export enum RoleEnum {
	Admin = 'admin',
	User = 'user',
}

export interface UserInterface {
	name: string;
}

export interface Usersinterface {
	[key: string]: UserInterface;
}
