import { useNavigate, Form } from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import CheckboxInput from '../../../components/shared/CheckboxInput';
import ModalButton from '../../../components/shared/Modal/ModalButton';

import { ADD_TEXT, EDITING_TEXT, REQUIRED_VALIDATION_RULE, SUBMITTING_TEXT } from '../../../constants';
import { showToast } from '../../../utils';

import RegionsStore from '../services/regions.store';
import { RegionInterface, RegionStatusEnum } from '../services/regions.types';

interface FormValuesInterface {
	region: string;
	// status: boolean;
}

interface RegionFormProps {
	region?: RegionInterface;
	onClose: () => void;
}

const regionsStore = new RegionsStore();

/**
 * Form for creating or editing a region.
 *
 * @component
 * @param {RegionFormProps} props - The props object for this component.
 * @param {RegionInterface} [props.region] - The region to edit, if any. If not provided, a new region will be created.
 * @param {() => void} props.onClose - A function to call when the form modal is closed.
 *
 * @returns {React.JSX.Element} The rendered RegionForm component.
 */
const RegionForm = ({
	region,
	onClose,
}: RegionFormProps): React.JSX.Element => {
	const navigate = useNavigate();
	const isEditing = Boolean(region);

	// React Hook Form methods and state initialization
	const methods = useForm<FormValuesInterface>({
		defaultValues: {
			region: region?.name || '',
			// status: region?.status === RegionStatusEnum.Active || false,
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	/**
	 * Handles form submission.
	 */
	const onSubmit: SubmitHandler<FieldValues> = async (
		data
	): Promise<void> => {
		//!add status 
		const { region: name } = data as FormValuesInterface;
		// const transformedStatus = status
		// 	? RegionStatusEnum.Active
		// 	: RegionStatusEnum.Inactive;

		const { success, message } = region?.id
			? await regionsStore.updateRegion({
					id: region.id,
					name,
					 status: 1,
			  })
			: await regionsStore.addRegion({
					name,
					 status: 1,
			  });

		showToast(message, success);
		onClose();
		navigate('.', { replace: true });
	};

	return (
		<>
			<h3 className='h2'>
				{isEditing ? 'Izmeni region' : 'Dodaj region'}
			</h3>
			<FormProvider {...methods}>
				<Form
					method='post'
					className='pks-layout-col-md w-full'
					onSubmit={handleSubmit(onSubmit)}>
					<div className='pks-layout-col'>
						{/* Email Input */}
						<Input
							type='text'
							id='region'
							name='region'
							label='Region *'
							placeholder='Unesi region'
							autoFocus
							autoComplete='off'
							validationRules={REQUIRED_VALIDATION_RULE}
						/>
						{/* <CheckboxInput
							id={isEditing ? region?.id || 'status' : 'status'}
							name='status'
							label='Aktivan'
							defaultChecked={
								isEditing ? region?.status === 1 : false
							}
						/> */}
					</div>
					{isEditing &&
						<ModalButton type='submit' disabled={isSubmitting}>
						{isSubmitting ? SUBMITTING_TEXT : EDITING_TEXT}
						</ModalButton>}
						{!isEditing &&
						<ModalButton type='submit' disabled={isSubmitting}>
						{isSubmitting ? SUBMITTING_TEXT : ADD_TEXT}
					</ModalButton>}
				</Form>
			</FormProvider>
		</>
	);
};

export default RegionForm;
