import { Link } from 'react-router-dom';

import InfoMessage from '../../../components/shared/InfoMessage';
import SeeMoreLink from '../../../components/shared/SeeMoreLink';

import { formatDateWithPoints } from '../../../utils';

/** Fraud Cases List and Skeleton reusable class names */
const FRAUD_CASES_CLASS_NAME = {
	holder: 'flex flex-col gap-3',
	item: 'pks-card-bg overflow-hidden rounded-lg',
	itemContent: 'pks-layout-col bg-white ml-[6px] pl-[22px] pr-4 py-3',
};

/** Interface for FraudCasesList component props */
interface FraudCasesListProps {
	data: any[]; // Array of fraud case objects
	preview?: boolean; // Optional flag to show "See More" link
}

/**
 * Fraud Cases List Component
 * Displays a list of fraud cases with links to detailed views and an optional "See More" link.
 *
 * @param {Object} props - Component props
 * @param {FraudCaseInterface[]} props.data - List of fraud cases to display.
 * @param {boolean} [props.preview=true] - Whether to show the "See More" link.
 * @returns {React.JSX.Element} - The rendered component.
 */
const FraudCasesList: React.FC<FraudCasesListProps> = ({
	data,
	preview = true,
}: {
	data: any[];
	preview?: boolean;
}): React.JSX.Element => {
	return (
		<>
			<ul className={FRAUD_CASES_CLASS_NAME.holder}>
				{data?.length === 0 ? (
					<li>
						<InfoMessage
							icon='info'
							message='Ne postoje rezultati za zadatu pretragu.'
						/>
					</li>
				) : (
					data.map((item) => {
						const formatedDate = formatDateWithPoints(
							item?.date_edited?.date || item?.date_created?.date
						);

						return (
							<li
								key={item.id}
								className={`${FRAUD_CASES_CLASS_NAME.item} pks-card-bg-hover`}>
								<Link
									to={`${item.id}`}
									className={
										FRAUD_CASES_CLASS_NAME.itemContent
									}>
									<p className='font-bold'>{item.title}</p>
									<span className='text-secondary-400'>
										{formatedDate}
									</span>
								</Link>
							</li>
						);
					})
				)}
			</ul>

			{preview && <SeeMoreLink url='fraud-cases' label='Svi slučajevi' />}
		</>
	);
};

export default FraudCasesList;

/**
 * Fraud Cases List Skeleton Component
 * Displays skeletons for the fraud cases list while data is loading.
 *
 * @param {Object} props - Component props
 * @param {number} [props.count=4] - Number of skeleton items to display.
 * @returns {React.JSX.Element} - The rendered skeleton component.
 */
export const FraudCasesListSkeleton = ({
	count = 4,
}: {
	count?: number;
}): React.JSX.Element => {
	const skeletonItems = Array.from({ length: count }, (_, index) => {
		return (
			<li
				key={index}
				className={`${FRAUD_CASES_CLASS_NAME.item} animate-pulse`}>
				<div
					className={`${FRAUD_CASES_CLASS_NAME.itemContent} justify-around`}>
					<p className='h-4 bg-gray-300 rounded'></p>
					<span className='h-4 bg-gray-300 rounded w-1/4'></span>
				</div>
			</li>
		);
	});

	return <ul className={FRAUD_CASES_CLASS_NAME.holder}>{skeletonItems}</ul>;
};
