import { Link } from 'react-router-dom';
import { HOME_PAGE_LINK } from '../../../constants';
import pksLogo from '../../../assets/images/logos/logo.png';

interface LogoProps {
	src?: string;
	alt?: string;
	isLink?: boolean;
	className?: string;
	preload?: boolean;
}

const Logo: React.FC<LogoProps> = ({
	src = pksLogo,
	isLink = true,
	className,
	alt = 'Privredna komora Srbije Logo',
}) => {
	const logoImg = (
		<img src={src} alt={alt} width={154} height={70} loading='eager' />
	);

	if (isLink) {
		return (
			<Link to='/administration/' className={className}>
				{' '}
				{/* SWITCH TO HOME */}
				{logoImg}
			</Link>
		);
	}

	return <div className={className}>{logoImg}</div>;
};

export default Logo;
