import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import FraudCaseSingle from '../../modules/fraudCases/UI/FraudCaseSingle';

function FraudCaseSinglePage() {
	return (
		<>
			<Meta title='Fraud Case' />
			<PageLayout>
				<FraudCaseSingle />
			</PageLayout>
		</>
	);
}

export default FraudCaseSinglePage;
