import http from '../../../utils/http';
import { FraudCaseInterface, FraudCasePaginatedInterface } from './index.types';

// Default URL parameters for education reports API requests
const URL_PARAMS = {
	LATEST_FRAUD_CASES: { limit: 7 },
	FRAUD_CASES: { page: 1, limit: 10, showLimitInUrl: false },
};

// Class to handle API requests for fraud cases
class FraudCasesApi {
	// Fetch the latest fraud cases
	fetchLatestFraudCases = () => {
		return http.get(
			`fraudCases?_limit=${URL_PARAMS.LATEST_FRAUD_CASES.limit}&_start=0`
		);
	};

	//Fetch paginated and filtered fraud cases
	fetchFraudCases = (page: number, limit: number, category?: string) => {
		return http.get('fraudCases', {
			params: {
				_page: page,
				_limit: limit,
				...(category && category !== '' ? { category } : {}), // Conditionally add category parameter
			},
			headers: { 'X-Total-Count': 'true' }, // Include header in the request
		});
	};
}

/** API Instance */
const api = new FraudCasesApi();

/**
 * Fetch the latest fraud cases
 * @returns {Promise<FraudCaseInterface[]>} - The latest fraud cases.
 */
async function fetchLatestFraudCases(): Promise<FraudCaseInterface[]> {
	const response = await api.fetchLatestFraudCases();
	const fraudCases = response.data;
	return fraudCases;
}

/**
 * Fetch paginated and filtered education reports.
 * @param {number} [page=URL_PARAMS.EDUCATION_REPORTS.page] - The page number to fetch, defaults to the value from URL_PARAMS.
 * @param {number} [limit=URL_PARAMS.EDUCATION_REPORTS.limit] - The number of reports per page, defaults to the value from URL_PARAMS.
 * @param {string} [category=''] - The category to filter the reports by, defaults to an empty string (no filtering).
 * @param {boolean} [showLimitInUrl=URL_PARAMS.EDUCATION_REPORTS.showLimitInUrl] - Whether to include the limit in the URL, defaults to the value from URL_PARAMS.
 * @returns {Promise<FraudCasePaginatedInterface>} - The paginated education reports and total count.
 */
async function fetchFraudCases(
	page: number = URL_PARAMS.FRAUD_CASES.page,
	limit: number = URL_PARAMS.FRAUD_CASES.limit,
	category: string = '',
	showLimitInUrl: boolean = URL_PARAMS.FRAUD_CASES.showLimitInUrl
): Promise<FraudCasePaginatedInterface> {
	const response = await api.fetchFraudCases(page, limit, category);
	const fraudCases = response.data;
	const total = parseInt(response.headers['x-total-count'], 10); // Get the total count from the headers
	return { total, fraudCases, category, showLimitInUrl };
}

/** Exported Functions and Constants */
export { URL_PARAMS, fetchLatestFraudCases, fetchFraudCases };
export default FraudCasesApi;
