import { Link } from 'react-router-dom';

const FilePreview = ({ file }: { file: any }) => {
	const linkProps = file.url ? { target: "_blank", rel: "noopener noreferrer" } : {};
	return (
		<div>
			<Link to={`${file.url ? file.url : "#"}`}  {...linkProps}  className='underline'>
				Uplodovani {file?.filename} 
			</Link>
		</div>
	);
};

export default FilePreview;
