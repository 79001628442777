import NewsPreview from '../../modules/news/UI/Preview';
import { ViewEnum } from '../../types';

const NewsSidebar = () => {
	return (
		<div className='pks-layout-col-xl'>
			<NewsPreview view={ViewEnum.Detailed} />
		</div>
	);
};

export default NewsSidebar;
