import { useFormContext, FieldError } from 'react-hook-form';

const useInputContext = (name: string) => {
	const {
		register,
		control,
		formState: { errors, isSubmitted },
		setValue,
		getValues,
	} = useFormContext();
	const values = getValues();
	const value = values[name];
	const error = errors[name] as FieldError;

	return {
		register,
		control,
		value,
		setValue,
		error,
		isSubmitted,
	};
};

export default useInputContext;
