import { SidebarType, RouteItemInterface, RouteGroupInterface } from '../types';

export const isPathMatching = (path: string, patterns: string[]): boolean => {
	return patterns.some((pattern) => {
		const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '[^/]+')}$`);
		return regex.test(path);
	});
};

export const extractPathsBySidebarValue = (
	routes: RouteGroupInterface,
	sidebarValue: SidebarType
): string[] => {
	const results: string[] = [];

	const traverse = (obj: RouteGroupInterface) => {
		for (const key in obj) {
			if (typeof obj[key] === 'object' && 'absPath' in obj[key]) {
				const route = obj[key] as RouteItemInterface;
				if (route.sidebar === sidebarValue) {
					results.push(route.absPath);
				}
			} else {
				traverse(obj[key] as RouteGroupInterface);
			}
		}
	};

	traverse(routes);
	return results;
};
