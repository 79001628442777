import * as ICONS from './Icons';

interface IconProps {
	name: keyof typeof ICONS;
	className?: string;
	tabIndex?: number;
}

const Icon: React.FC<IconProps> = ({ name, className, tabIndex }) => {
	const Icon = ICONS[name];

	if (!Icon) {
		return null;
	}

	return (
		<div tabIndex={tabIndex} className={className}>
			{Icon}
		</div>
	);
};

export default Icon;
