export function getBaseUrl() {
	return process.env.REACT_APP_API_URL;
}

export function getPageTitle(title: string) {
	return `${title} | PKS`;
}

export function testRegex(regex: RegExp, input: string): boolean {
	return regex.test(input);
}

/**
 * Debounce function to limit how often a function is executed.
 * @param func - The function to debounce.
 * @param wait - The debounce delay in milliseconds.
 * @returns A debounced version of the function.
 */
/**
 * Creates a debounced function that delays invoking the provided function
 * until after a specified wait time has elapsed since the last time it was invoked.
 * @param func - The function to debounce.
 * @param wait - The debounce delay in milliseconds (default is 250ms).
 * @returns A debounced version of the function with a cancel method.
 */
export function debounce<T extends (...args: any[]) => void>(
	func: T,
	delay: number = 250
) {
	let timeout: ReturnType<typeof setTimeout> | null = null;

	return function (this: any, ...args: Parameters<T>): void {
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(this, args), delay);
	} as T;
}
