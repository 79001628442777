/**
 * NewsSkeleton Component
 *
 * This component displays a skeleton placeholder for the news items
 * while the actual news data is being loaded. It uses different styles
 * depending on the `view` prop to represent different layout modes.
 *
 * @param {Object} props - The props object for the component.
 * @param {number} [props.count=4] - The number of skeleton items to display.
 * @param {string} [props.view='compact'] - The view type, which determines the layout style ('compact', 'detailed', 'grid').
 * @returns {React.JSX.Element} The rendered NewsSkeleton component.
 */
const NewsSkeleton = ({
	count = 4,
	view = 'compact',
}: {
	count?: number;
	view?: string;
}): React.JSX.Element => {
	// Determine the main container class based on the view type
	const placeholderClassName = `pks-layout-col-md ${
		view === 'compact'
			? ''
			: `sm:grid sm:grid-cols-2 ${
					view === 'detailed' ? 'lg:grid-cols-1' : ''
			  }`
	}`;

	// Determine the class for each placeholder item
	const placeholderItemClassName = `animate-pulse ${
		view === 'compact'
			? 'flex items-center gap-2 pb-4 border-b border-b-secondary-200'
			: ''
	}`;

	// Determine the class for the placeholder image element
	const placeholderImgClassName = `overflow-hidden rounded-lg bg-secondary-200 ${
		view === 'compact'
			? 'flex-none aspect-square w-[60px] h-[60px]'
			: 'aspect-video mb-4'
	}`;

	// Determine the class for the placeholder content container
	const placeholderContentClassName = `space-y-2 py-2 ${
		view === 'compact' ? 'flex-1' : ''
	}`;

	// Class for individual placeholder text elements
	const placeholderTxtClassName = 'h-4 bg-gray-300 rounded';

	// Create an array of skeleton items based on the count
	const skeletonItems = Array.from({ length: count }, (_, index) => {
		// Specific class adjustment for the first item in grid view
		const firstGridItemClassName =
			view === 'grid' && index === 0 ? 'col-span-full' : 'w-full';

		return (
			<li
				key={index}
				className={`${placeholderItemClassName} ${firstGridItemClassName}`}>
				<div className={placeholderImgClassName}></div>
				<div className={placeholderContentClassName}>
					<div className={placeholderTxtClassName}></div>
					<div className={`${placeholderTxtClassName} w-3/4`}></div>
				</div>
			</li>
		);
	});

	// Render the list of skeleton items
	return <ul className={placeholderClassName}>{skeletonItems}</ul>;
};

export default NewsSkeleton;
