import { Link } from 'react-router-dom';

import InfoMessage from '../../../components/shared/InfoMessage';
// import StatusIndicator from '../../../components/shared/StatusIndicator';
import { EDITING_TEXT } from '../../../constants';
import Button from '../../../components/shared/Button';
import { formatDateWithPoints } from '../../../utils';

const OrganizationsList = ({ organizations, searchValue, onClick }: any) => {
	return (
		<ul className='flex flex-col gap-3'>
			{organizations?.length === 0 && searchValue !== '' ? (
				<li>
					<InfoMessage
						icon='info'
						message='Ne postoje rezultati za zadatu pretragu.'
					/>
				</li>
			) : (
				organizations?.map((organization: any) => {
					const formatedDate = formatDateWithPoints(
						organization?.date_edited?.date ||
							organization?.date_created?.date
					);
					return (
						<li
							key={organization.id}
							className='pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer'
							/* onClick={() => onUpdate(region)} */
						>
							<div className='flex flex-col sm:flex-row gap-4 sm:items-center sm:justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white'>
								<div className='flex gap-2 items-center'>
									{/* <>
										{organization.status === 'active' ||
										1 ? (
											<StatusIndicator
												label={'Aktivna'}
												active
											/>
										) : (
											<StatusIndicator
												label={'Neaktivna'}
											/>
										)}
									</> */}
									<div className='pks-layout-col'>
										<p className='font-bold'>
											{organization.name}
										</p>
										<span className='text-secondary-400'>
											{formatedDate}
										</span>
									</div>
								</div>
								<div className='inline-flex flex-wrap gap-4'>
									<Button
										variant='secondary'
										className='flex-auto'
										onClick={() => onClick(organization)}>
										Detalji
									</Button>
									<Link
										to={`${organization.id}`}
										className='flex-auto'>
										<Button wide>{EDITING_TEXT}</Button>
									</Link>
								</div>
							</div>
						</li>
					);
				})
			)}
		</ul>
	);
};

export default OrganizationsList;
