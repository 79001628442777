import http from '../../../utils/http';
import { RegionAddInterface, RegionEditInterface } from './regions.types';

class RegionsApi {
	// Fetch Regions
	async getRegions(page?: number, searchTerm?: string) {
		const params = new URLSearchParams();

		if (page) {
			params.append('page', page.toString());
		}

		if (searchTerm) {
			params.append('name', searchTerm);
		}

		// Construct the full URL with the query string
		const url = `/regions${
			params.toString() ? `?${params.toString()}` : ''
		}`;

		return http.get(url);
	}

	// Add Region
	async addRegion({ name, status }: RegionAddInterface) {
		return http.post(`/regions`, {
			name,
			status: status,
		});
	}

	// Update Region
	async updateRegion({ id, name, status }: RegionEditInterface) {
		return http.post(`/regions/${id}`, {
			name,
			status: status,
		});
	}
}

export default RegionsApi;
