import React, { useCallback, useMemo } from 'react';
import InfoMessage from '../../../components/shared/InfoMessage';
import CheckboxAccordion from '../../../components/shared/Accordion/CheckboxAccordion';
import { PermissionInterface } from '../services/role.types';

interface PermissionsListProps {
	permissions: PermissionInterface[];
	permissionsPerRole: number[];
	onCheckedItemsChange: (value: number[]) => void;
}

const PermissionsList: React.FC<PermissionsListProps> = ({
	permissions,
	permissionsPerRole,
	onCheckedItemsChange,
}) => {
	// Group permissions by their group and check if all items in the group are checked
	const groupedPermissions = useMemo(() => {
		return permissions.reduce<
			Record<string, { items: any[]; allChecked: boolean }>
		>((acc, permission) => {
			if (!acc[permission.group]) {
				acc[permission.group] = { items: [], allChecked: false };
			}

			const isChecked = permissionsPerRole.includes(permission.id);

			acc[permission.group].items.push({
				...permission,
				checked: isChecked,
			});

			// Check if all items in the group are checked
			acc[permission.group].allChecked = acc[
				permission.group
			].items.every((item) => item.checked);

			return acc;
		}, {});
	}, [permissions, permissionsPerRole]);

	// Handle the change of a single checkbox within a group
	const handleCheckboxChange = useCallback(
		(_groupId: string, itemId: number, checked: boolean) => {
			const updatedCheckedItems = checked
				? [...permissionsPerRole, itemId]
				: permissionsPerRole.filter((id) => id !== itemId);

			// Pass the updated checked items directly to the parent
			onCheckedItemsChange(updatedCheckedItems);
		},
		[permissionsPerRole, onCheckedItemsChange]
	);

	// Handle the change of a group label checkbox
	const handleLabelCheckboxChange = useCallback(
		(groupId: string, checked: boolean) => {
			const groupItems = groupedPermissions[groupId].items.map(
				(item) => item.id
			);
			const updatedCheckedItems = checked
				? Array.from(new Set([...permissionsPerRole, ...groupItems]))
				: permissionsPerRole.filter((id) => !groupItems.includes(id));

			// Pass the updated checked items directly to the parent
			onCheckedItemsChange(updatedCheckedItems);
		},
		[groupedPermissions, permissionsPerRole, onCheckedItemsChange]
	);

	return (
		<ul className='flex flex-col gap-3'>
			{permissions.length === 0 ? (
				<li>
					<InfoMessage
						icon='info'
						message='Ne postoje rezultati za zadatu pretragu.'
					/>
				</li>
			) : (
				<div className='flex flex-col gap-3'>
					{Object.entries(groupedPermissions).map(
						([group, { items: groupPermissions, allChecked }]) => (
							<CheckboxAccordion
								key={group}
								groupId={group}
								label={group}
								labelChecked={allChecked}
								items={groupPermissions.map((perm) => ({
									id: perm.id,
									name: perm.description,
									checked: perm.checked,
								}))}
								onCheckboxChange={handleCheckboxChange}
								onLabelCheckboxChange={
									handleLabelCheckboxChange
								}
								// Pass whether the entire group is checked
							/>
						)
					)}
				</div>
			)}
		</ul>
	);
};

export default PermissionsList;
