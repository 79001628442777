import { Helmet } from 'react-helmet-async';
import { WEBSITE_NAME } from '../../../constants';
import { getPageTitle } from '../../../utils';

interface MetaProps {
	title?: string;
}

const Meta: React.FC<MetaProps> = ({ title }) => {
	const pageTitle = title ? getPageTitle(title) : WEBSITE_NAME;

	return (
		<Helmet>
			<title>{pageTitle}</title>
		</Helmet>
	);
};

export default Meta;
