import StatusIndicator from '../../../components/shared/StatusIndicator';

const OrganizationPreview = ({ organization, regionName }: any) => {
	const organizationItemClassName = 'h3 font-normal flex flex-col gap-1';
	const emptyPropText = '/';

	return (
		<ul className='flex flex-col gap-3' id={organization.region_id}>
			<li className={organizationItemClassName}>
				<div className='flex flex-row items-center gap-2 sm:flex-col sm:items-start'>
					{/* <div className='w-auto'>
						{organization.status === 'active' || 1 ? (
							<StatusIndicator label={'Aktivna'} active />
						) : (
							<StatusIndicator label={'Neaktivna'} />
						)}
					</div> */}
					<b>Naziv organizacije</b>
				</div>{' '}
				{organization.name}
			</li>
			<li className={organizationItemClassName}>
				<b>Adresa sedišta organizacije</b>{' '}
				{organization.address || emptyPropText}
			</li>
			<li className={organizationItemClassName}>
				<b>Grad</b> {organization.city || emptyPropText}
			</li>
			<li className={organizationItemClassName}>
				<b>Poštanski broj</b> {organization.postalCode || emptyPropText}
			</li>
			<li className={organizationItemClassName}>
				<b>Država</b> {organization.country || emptyPropText}
			</li>
			<li className={organizationItemClassName}>
				<b>Domen organizacije:</b> {organization.domain || emptyPropText}
			</li>
			<li className={organizationItemClassName}>
				<b>Region</b> {regionName || emptyPropText}
			</li>
		</ul>
	);
};

export default OrganizationPreview;
