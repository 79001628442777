import { Link } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import Button from '../../components/shared/Button';
import Organizations from '../../modules/organizations/UI';

import { ADD_ORGANIZATION, ORGANIZATION_ADD_PATH, REGIONS_PATH } from '../../constants';

function OrganizationsPage() {
	return (
		<>
			<Meta title='Organizacije'></Meta>
			<PageLayout>
				<PageTitleWithActions title='Organizacije'>
					<div className='inline-flex flex-wrap gap-4'>
						<Link
							to={ORGANIZATION_ADD_PATH}
							className='flex-auto focus:outline-none group'>
							<Button wide tabIndex={-1}>
								{ADD_ORGANIZATION}
							</Button>
						</Link>
						{/* <Link
							to={REGIONS_PATH}
							className='flex-auto focus:outline-none group'>
							<Button wide tabIndex={-1}>
								Upravljaj regionima
							</Button>
						</Link> */}
					</div>
				</PageTitleWithActions>
				<Organizations />
			</PageLayout>
		</>
	);
}

export default OrganizationsPage;
