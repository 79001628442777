import http from '../../../utils/http';
import {
	RoleAddInterface,
	RoleEditInterface,
	PermissonsIdsInterface,
} from './role.types';

class RolesApi {
	/* Roles */
	async getRoles(page?: number) {
		const params = new URLSearchParams();

		if (page) {
			params.append('page', page.toString());
		}

		// Construct the full URL with the query string
		const url = `/roles${params.toString() ? `?${params.toString()}` : ''}`;

		return http.get(url);
	}

	async addRole({ name, status }: RoleAddInterface) {
		return http.post(`/roles`, {
			name,
			status: status,
		});
	}

	async updateRole({ id, name, status }: RoleEditInterface) {
		return http.post(`/roles/${id}`, {
			name,
			status: status,
		});
	}

	/* Permissions */
	async getPermissions(page?: number) {
		return http.get(`/permissions?page=${page}`);
	}

	async getPermisionsPerRole(id: number) {
		return http.get(`/roles/${id}/permissions`);
	}

	async updateRolePermissions(id: number, permissionIds: number[]) {
		const formData = new FormData();

		permissionIds.forEach((permissionId) => {
			formData.append('permissionIds[]', permissionId.toString());
		});

		return http.post(`/roles/${id}/permissions`, formData);
	}
}

export default RolesApi;
