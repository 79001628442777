import { useLoaderData, useNavigate } from 'react-router-dom';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import CustomDateRangePicker from '../../../components/shared/DateRangePicker';
import FileInput from '../../../components/shared/FileInput';
import DropdownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Button from '../../../components/shared/Button';

/* Fields Validation */
import {
	REQUIRED_VALIDATION_RULE,
	/* BIN_VALIDATION_RULES,
	ACQUIRER_ID_VALIDATION_RULES,
	MID_VALIDATION_RULES,
	TID_VALIDATION_RULES,
	NUMBERS_VALIDATION_RULES, */
	LETTERS_REQUIRED_VALIDATION_RULES,
	LOADING_TEXT,
	FRAUD_CASES_LINK,
	CANCEL,
} from '../../../constants';

import { DropDownItemInterface } from '../../../types';
import {
	extractMultipleErrors,
	formatToISOTYmd,
	hasNestedObjects,
	showToast,
} from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
import { Link } from 'react-router-dom';
const fraudCasesStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseForm = () => {
	const navigate = useNavigate();
	// Get loader data to populate 'edit' form
	const loaderData = useLoaderData() as any;

	// Use the `useGetFraudCasesTypes` hook from the fraud cases store to fetch fraud case types data
	const { data: fraudCasesTypes, isLoading: isLoadingFraudCasesTypes } =
		fraudCasesStore.useGetFraudCasesTypes();
	const fraudCaseOptions: DropDownItemInterface[] =
		fraudCasesTypes && typeof fraudCasesTypes === 'object'
			? Object.keys(fraudCasesTypes).map((key) => ({
					id: key,
					label: fraudCasesTypes[key],
					index: key === loaderData?.type_id,
			  }))
			: [];

	// Use the `useGetFraudCasesTerminalTypes` hook from the fraud cases store to fetch fraud case terminal types data
	const {
		data: fraudCasesTerminalTypes,
		isLoading: isLoadingFraudCasesTerminalTypes,
	} = fraudCasesStore.useGetFraudCasesTerminalTypes();
	const fraudCaseTerminalOptions: DropDownItemInterface[] =
		fraudCasesTerminalTypes && typeof fraudCasesTerminalTypes === 'object'
			? Object.keys(fraudCasesTerminalTypes).map((key, index) => ({
					id: index + 1,
					label: fraudCasesTerminalTypes[key], // Automatically get the value associated with the key
			  }))
			: [];

	// Use the `useGetFraudCardTypes` hook from the fraud cases store to fetch fraud case card types data
	const {
		data: fraudCasesCardTypes,
		isLoading: isLoadingfraudCasesCardTypes,
	} = fraudCasesStore.useGetFraudCardTypes();
	const fraudCaseCardOptions: DropDownItemInterface[] =
		fraudCasesCardTypes && typeof fraudCasesCardTypes === 'object'
			? Object.keys(fraudCasesCardTypes).map((key) => ({
					id: key,
					label: fraudCasesCardTypes[key], // Automatically get the value associated with the key
			  }))
			: [];

	// Initialize React Hook Form methods with default values
	const methods = useForm<FieldValues>({
		defaultValues: {
			title: loaderData?.title || '',
			description: loaderData?.description || '',
			typeId: loaderData?.type_id ? loaderData.type_id : '',
			cardTypeId: loaderData?.card_type_id
				? loaderData.card_type_id.toString()
				: '',
			terminalTypeId: loaderData?.terminal_type_id
				? loaderData.terminal_type_id.toString()
				: '',
			cardNumber: loaderData?.card_number || '',
			acquirerId: loaderData?.acquirer_id || '',
			mid: loaderData?.mid || '',
			tid: loaderData?.tid || '',
			inputDateStart: loaderData?.input_date_start?.date || '',
			inputDateEnd: loaderData?.input_date_end?.date || '',
			fraudDateStart: loaderData?.fraud_date_start?.date || '',
			fraudDateEnd: loaderData?.fraud_date_end?.date || '',
			documents: [],
		},
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
		setError,
		reset,
	} = methods;

	/**
	 * Handles form submission.
	 *
	 * @param {FieldValues} data - The form data.
	 * @returns {Promise<void>} A promise that resolves when the submission is complete.
	 */
	const onSubmit: SubmitHandler<FieldValues> = async (
		data: FieldValues
	): Promise<void> => {
		const formData = new FormData();

		// Format and append all non-file, non-date fields
		Object.keys(data).forEach((key) => {
			if (
				![
					'documents',
					'inputDateStart',
					'inputDateEnd',
					'fraudDateStart',
					'fraudDateEnd',
				].includes(key)
			) {
				formData.append(key, data[key]);
			}
		});

		// Append formatted dates if they exist
		[
			'inputDateStart',
			'inputDateEnd',
			'fraudDateStart',
			'fraudDateEnd',
		].forEach((dateField) => {
			formData.append(dateField, formatToISOTYmd(data[dateField]));
		});

		// Append files (if documents are provided)
		if (data.documents && data.documents.length > 0) {
			(Array.from(data.documents) as File[]).forEach((file: File) => {
				formData.append(`documents[]`, file);
			});
		}

		const response = await fraudCasesStore.addFraudCase(formData);
		const { success, message } = response;

		if (success) {
			const resData = response.data;

			if (!resData) {
				// Show toast error
				showToast(message, !success);
				return;
			}

			navigate(FRAUD_CASES_LINK);
			showToast(message, success);
		} else {
			if (hasNestedObjects(message)) {
				// Set input fields errors
				const errorMessages = extractMultipleErrors(message);

				for (const key in errorMessages) {
					setError(key, {
						type: 'backend',
						message: errorMessages[key],
					});
				}
			} else {
				// Show toast error
				showToast(message, success);
			}

			reset({}, { keepValues: true });
		}
		//reset({}, { keepValues: success ? false : true });
	};

	return (
		<div className='max-w-[1016px]'>
			{/* Form wrapped in FormProvider to use React Hook Form context */}
			<FormProvider {...methods}>
				<form
					className='pks-layout-col-md'
					onSubmit={handleSubmit(onSubmit)}>
					{/* Form inputs */}
					<div className='flex flex-col gap-4 lg:flex-row'>
						{/* Left Panel */}
						<div className='pks-layout-col-md w-1/2'>
							<div className='pks-layout-col'>
								{/* Title Input Field */}
								<Input
									id='title'
									name='title'
									label='Naziv slučaja *'
									placeholder='Unesi naziv slučaja'
									autoComplete='off'
									validationRules={
										LETTERS_REQUIRED_VALIDATION_RULES
									}
								/>

								{/* Type of Fraud Cases Dropdown Select */}
								<div className='max-w-full'>
									<DropdownSelect
										id='typeId'
										name='typeId'
										options={fraudCaseOptions}
										label='Vrsta prevare *'
										placeholder='Izaberi vrstu prevare'
										validationRules={
											REQUIRED_VALIDATION_RULE
										}
										isLoading={isLoadingFraudCasesTypes}
									/>
								</div>

								{/* Type of Fraud Cases Card Dropdown Select */}
								<div>
									<DropdownSelect
										id='cardTypeId'
										name='cardTypeId'
										options={fraudCaseCardOptions}
										label='Tip kartice'
										placeholder='Izaberi tip kartice'
										isLoading={isLoadingfraudCasesCardTypes}
									/>
								</div>

								{/* Type of Fraud Cases Terminal Dropdown Select */}
								<div>
									<DropdownSelect
										id='terminalTypeId'
										name='terminalTypeId'
										options={fraudCaseTerminalOptions}
										label='Tip terminala'
										placeholder='Izaberi tip terminala'
										isLoading={
											isLoadingFraudCasesTerminalTypes
										}
									/>
								</div>

								{/* Bin and Acquirer ID Input Fields*/}
								<div className='flex flex-col gap-2 lg:flex-row'>
									<div className='flex-1'>
										<Input
											id='cardNumber'
											name='cardNumber'
											label='BIN / broj kartice'
											placeholder='Unesi broj'
											/* validationRules={
												BIN_VALIDATION_RULES
											} */
											//maxLength={16}
											autoComplete='off'
											//numeric
										/>
									</div>
									<div className='flex-1'>
										<Input
											id='acquirerId'
											name='acquirerId'
											label='Acquirer ID'
											placeholder='Unesi acquirer ID'
											//maxLength={10}
											autoComplete='off'
											//numeric
										/>
									</div>
								</div>

								{/* MID and TID Input Fields*/}
								<div className='flex flex-col gap-2 lg:flex-row'>
									<div className='flex-1'>
										<Input
											id='mid'
											name='mid'
											label='MID'
											placeholder='Unesi MID'
											autoComplete='off'
											/* validationRules={
												MID_VALIDATION_RULES
											} */
											//numeric
											//maxLength={15}
										/>
									</div>
									<div className='flex-1'>
										<Input
											id='tid'
											name='tid'
											label='TID'
											placeholder='Unesi TID'
											autoComplete='off'
											/* validationRules={
												TID_VALIDATION_RULES
											} */
											maxLength={10}
										/>
									</div>
								</div>

								{/* Entry Date Input Field */}
								<div className='flex flex-col gap-2 lg:flex-row'>
									<CustomDateRangePicker
										idFrom='inputDateStart'
										nameFrom='inputDateStart'
										labelFrom='Datum unosa od'
										/* validationRulesFrom={
											REQUIRED_VALIDATION_RULE
										} */
										idTo='inputDateEnd'
										nameTo='inputDateEnd'
										labelTo='Datum unosa do'
										/* validationRulesTo={
											REQUIRED_VALIDATION_RULE
										} */
									/>
								</div>

								{/* Fraud Date Input Field */}
								<div className='flex flex-col gap-2 lg:flex-row'>
									<CustomDateRangePicker
										idFrom='fraudDateStart'
										nameFrom='fraudDateStart'
										labelFrom='Datum prevare od *'
										validationRulesFrom={
											REQUIRED_VALIDATION_RULE
										}
										idTo='fraudDateEnd'
										nameTo='fraudDateEnd'
										labelTo='Datum prevare do'
										/* validationRulesTo={
											REQUIRED_VALIDATION_RULE
										} */
									/>
								</div>
							</div>
							{/* Documents File Input Field */}
							<FileInput
								id='documents'
								name='documents'
								label='Dodaj dokument: *'
								maxSizeMB={16}
								multiple
								required
							/>
							<div className='w-full inline-flex flex-wrap gap-4'>
					{/* Submit Button */}
					<Button type='submit'
							className='flex-grow'>
						{isSubmitting
							? LOADING_TEXT
							: loaderData
							? 'Izmeni slučaj'
							: 'Unesi slučaj'}
						</Button>
						<Link
							to='..'
							className='flex-auto sm:flex-none focus:outline-none group'>
							<Button
								type='button'
								variant='secondary'
								wide
								tabIndex={-1}>
									{CANCEL}

							</Button>
						</Link>
				</div>
						</div>

						{/* Right Panel */}
						<div className='w-1/2'>
							{/* Additional Note Textarea */}
							<Input
								id='description'
								name='description'
								label='Napomena / Opis *'
								placeholder='Unesi napomenu / opis'
								asTextarea
								height={'200'}
								validationRules={REQUIRED_VALIDATION_RULE}
								data-gramm='false'
							/>
						</div>
					</div>
					
				</form>
			</FormProvider>
		</div>
	);
};

export default FraudCaseForm;
