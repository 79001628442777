import http from '../../../utils/http';
import { StatisticInterface } from './index.types';

// Class to handle API requests for news data
class StatisticsApi {
	// Fetch statistics data
	fetchStatistics = () => {
		// return http.get('/statistics');
	};
}

/** API Instance */
const api = new StatisticsApi();

/**
 * Fetch statistics data from the API.
 * @returns {Promise<StatisticInterface[]>} - The fetched statistics data.
 */
async function fetchStatistics(): Promise<StatisticInterface[]> {
	const response = await api.fetchStatistics();
	// const articles = response.data;
	// return articles;
	return [];
}

/** Exported Functions and Constants */
export { fetchStatistics };
export default StatisticsApi;
