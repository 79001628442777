import { Link } from 'react-router-dom';

import Logo from '../../shared/Logo';
import logo from '../../../assets/images/logos/logo-white.png';
import SocialMedia from '../../shared/SocialMedia';
import Icon from '../../shared/Icon';

import { QUICK_LINKS, CONTACT_INFO_LINKS } from '../../../constants';

const Footer = () => {
	return (
		<footer className='bg-secondary pt-16 pb-4 text-white'>
			<div className='pks-container'>
				<div className='pks-layout-col-xl'>
					<div className='flex flex-col gap-16 md:flex-row md:justify-between'>
						{/* Company Logo and Social Media */}
						<div className='pks-layout-col-xl'>
							<Logo src={logo} />
							<SocialMedia />
						</div>
						{/* Contact Info */}
						<div className='pks-layout-col-xl'>
							<div className='h3'>Kontakt informacije</div>
							<ul className='pks-layout-col-md'>
								{CONTACT_INFO_LINKS.map((link) => {
									const {
										id,
										label,
										url,
										external,
										ariaLabel,
									} = link;
									return (
										<li key={id}>
											{url && (
												<a
													className='inline-flex gap-2 hover:text-primary-300'
													href={url}
													aria-label={ariaLabel}
													target={
														external
															? '_blank'
															: undefined
													}
													rel={
														external
															? 'noopener noreferrer'
															: undefined
													}>
													<Icon name={id} />
													<span
														className='block'
														dangerouslySetInnerHTML={{
															__html: label,
														}}></span>
												</a>
											)}
											{!url && (
												<span className='inline-flex gap-2'>
													<Icon name={id} />
													<span
														className='block'
														dangerouslySetInnerHTML={{
															__html: label,
														}}></span>
												</span>
											)}
										</li>
									);
								})}
							</ul>
						</div>
						{/* Quick Links */}
						<div className='pks-layout-col-xl min-w-44'>
							<div className='h3'>Quick links</div>
							<nav>
								<ul className='pks-layout-col-md'>
									{QUICK_LINKS.map((link, index) => (
										<li key={index}>
											<Link
												className='block hover:text-primary-300'
												to={link.url}>
												{link.label}
											</Link>
										</li>
									))}
								</ul>
							</nav>
						</div>
					</div>
					<div className='py-4 border-t border-t-white'>
						<p>All Rights Reserved, {new Date().getFullYear()}</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
