import { toast } from 'react-toastify';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { getBaseUrl } from './global';
import { getAuthStateFromLocalStorage } from './auth';
import { ErrorResponse } from '../types';

const config: AxiosRequestConfig = {
	baseURL: getBaseUrl(),
};

interface HeadersCustomInterface {
	[key: string]: string;
}

let headers: HeadersCustomInterface = {
	'Content-Type': 'multipart/form-data',
};

export const setHeaders = (newHeaders: HeadersCustomInterface): void => {
	headers = { ...headers, ...newHeaders };
};

export const removeHeader = (header: string): void => {
	const { [header]: _, ...rest } = headers;
	headers = rest;
};

const http = axios.create(config);

http.interceptors.request.use((config) => {
	// Retrieve token from local storage
	const { token } = getAuthStateFromLocalStorage() || {};

	// Set the token in headers if it exists
	if (token) {
		setHeaders({ Authorization: `Bearer ${token}` });
	}

	for (const header in headers) {
		config.headers[header] = headers[header];
	}
	// Axios middleware to convert all api requests to snake_case, add if needed
	return config;
});

export type InterceptorFunction = (error: AxiosError) => void;
export const interceptors: InterceptorFunction[] = [];

http.interceptors.response.use(
	(response: AxiosResponse) => {
		// Optionally, format response data here if needed
		return response;
	},
	(error: AxiosError) => {
		interceptors.forEach((fn) => fn(error));
		return Promise.reject(error);
	}
);

export function handleErrors(error: unknown) {
	const resError = error as AxiosError;

	// Safely access the response data from the error object
	const data = resError?.response?.data;

	if (data) {
		// Assuming that data conforms to ErrorResponse
		const { message, success } = data as ErrorResponse;
		// Return the structured error response
		return {
			message: message || (data as Record<any, any>),
			success,
		};
	} else {
		// Handle the case where there's no data in the error response
		return {
			message: 'Something went wrong.',
			success: false,
		} as ErrorResponse;
	}
}

interface ErrorObject {
	[key: string]: any | undefined;
}

export const getFirstErrorMessage = (
	errors: ErrorObject,
	success: boolean
): string => {
	const fallbackText = success ? 'Success!' : 'Something went wrong';
	if (!errors || typeof errors !== 'object') {
		return fallbackText;
	}

	// Helper function to recursively find the first error message
	const findFirstError = (obj: ErrorObject): string => {
		for (const key in obj) {
			const value = obj[key];
			if (typeof value === 'string') {
				// Return the first string value found
				return value;
			} else if (typeof value === 'object' && value !== null) {
				// Recursively search through nested objects
				const nestedError = findFirstError(value as ErrorObject);
				if (nestedError) {
					return nestedError;
				}
			}
		}
		return ''; // Return an empty string if no error message is found
	};

	const firstErrorMessage = findFirstError(errors);
	return firstErrorMessage || fallbackText;
};

export const extractMultipleErrors = (
	errorObj: any
): Record<string, string> => {
	const errorState: Record<string, string> = {};

	for (const key in errorObj) {
		if (errorObj[key] && typeof errorObj[key] === 'object') {
			const subKeys = Object.keys(errorObj[key]);
			const firstSubPropKey = subKeys[0]; // Get the first sub-property key

			if (firstSubPropKey) {
				const firstSubPropValue = errorObj[key][firstSubPropKey];
				if (typeof firstSubPropValue === 'string') {
					errorState[key] = firstSubPropValue; // Ensure the value is a string
				}
			}
		}
	}

	return errorState;
};

/**
 * Displays a toast notification with the specified message, indicating success or error.
 * If the `dismiss` parameter is true, any existing toast notifications will be dismissed before displaying the new one.
 *
 * @param {string | ErrorObject} message - The message to display in the toast. It can be a string or an `ErrorObject`.
 *                                        If an `ErrorObject` is provided, the error message will be extracted using `getFirstErrorMessage`.
 * @param {boolean} success - A boolean indicating whether the toast should show a success message (`true`) or an error message (`false`).
 *
 * @returns {void} This function does not return a value.
 */
export const showToast = (
	message: string | ErrorObject,
	success: boolean,
	dismiss?: boolean
): void => {
	(success || dismiss) && toast.dismiss();

	if (typeof message === 'string') {
		if (success) {
			toast.success(message);
		} else {
			toast.error(message);
		}
	} else {
		const finalMessage = getFirstErrorMessage(message, success);
		toast.error(finalMessage);
	}
};

export default http;
