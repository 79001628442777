import { ReactNode } from 'react';

interface SidebarLayoutWrapperProps {
	children: ReactNode;
}

const SidebarLayoutWrapper: React.FC<SidebarLayoutWrapperProps> = ({
	children,
}) => {
	return (
		<aside className='lg:w-[391px] border-secondary-400 border-t pt-8 lg:border-l lg:border-t-0 lg:pt-0 lg:pl-8'>
			{children}
		</aside>
	);
};

export default SidebarLayoutWrapper;
