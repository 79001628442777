import { NavLink,  useLocation } from 'react-router-dom';
import NestedLink from './NestedLink';
import { useCallback, useEffect, useState } from 'react';
import Icon from '../../shared/Icon';
import { MainLinkProps } from '../../../types';

const MainLink = ({ to, label, childrenMenu, setIsDrawerOpen, onToggle, onToggleSubmenu, clickedSubMenu, active }: MainLinkProps) => {
const location = useLocation()

  const navLinkClasses = 'flex items-center h-full  px-2 py-3 lg:border-b-2 lg:hover:border-b-primary-300 lg:hover:text-white lg:hover:bg-primary-300 lg:group-hover:bg-primary-300 lg:group-hover:text-white   ';

  const getNavLinkClassName = (isActive: boolean) => {
    return `${navLinkClasses} ${isActive ? 'text-primary-300 border-b-primary-300' : 'border-b-transparent text-black'}`;
  };
  
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  useEffect(() => {
    if(isMenuOpen) setIsMenuOpen(false)
    }, [location]) 
  
  const handleMouseEnter = useCallback(() => {
  setIsMenuOpen(true);
}, []);

const handleMouseLeave = useCallback(() => {
  setIsMenuOpen(false);
}, []);

  return (
    <li className="h-full group inline-block relative w-full lg:w-fit pl-2 lg:pl-0 cursor-pointer lg:cursor-default" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="h-full  w-full flex items-center justify-between" onClick={onToggle}>
        <NavLink
          onClick={() => setIsDrawerOpen && setIsDrawerOpen(false)}
          to={to}
          className={({ isActive }) => getNavLinkClassName(isActive)}
          >
          {label}
        </NavLink>

        {childrenMenu && (
          <button className="lg:hidden pr-6 lg:pr-0" aria-label="Toggle dropdown" aria-haspopup="menu" aria-expanded={active ? 'true' : 'false'}>
            {active ? <Icon name="minus" className="" /> : <Icon name="plus" className="" />}
          </button>
        )}
      </div>

      {childrenMenu && (
        <div
          className={`lg:bg-white w-full lg:drop-shadow-md   lg:w-[798px]  lg:hidden lg:fixed ${isMenuOpen && "lg:group-hover:flex"}  sm:border-none bg-primary-50  lg:border-t lg:border-gray-300 left-1/2 lg:-translate-x-1/2  lg:py-0   ${
            active ? 'h-[auto]  py-0 flex ' : 'h-0 overflow-hidden lg:h-auto'
          }`}
        >
          <div className="flex lg:flex-wrap flex-col lg:flex-row lg:py-8   lg:gap-16 gap-0    lg:bg-white w-full lg:w-full lg:items-start lg:justify-start lg:px-8 lg:border lg:border-t lg:border-gray-300 h-full   ">
            {childrenMenu?.map((child: any, childIndex: any):any => (
              <div key={childIndex} className="flex flex-col"
              onClick={() => onToggleSubmenu && onToggleSubmenu(childIndex)}
              >
                <div  className="h-full lg:h-fit flex  flex-col w-full lg:w-fit  items-start justify-between lg:justify-normal   ">
                  <div className="flex items-center justify-between w-full    pr-6 lg:pr-0">
                    <p className="font-bold px-3 py-2 lg:px-0 ">{child.heading}</p>
                    {
                     childIndex === clickedSubMenu ? <Icon name="chevronDown" className=" lg:hidden rotate-180" /> : <Icon name="chevronDown" className=" lg:hidden " />
                    }
                  </div>
                </div>
                <ul className={`  ${ childIndex === clickedSubMenu ? 'h-auto' : 'h-0 overflow-hidden lg:h-auto'}`}>
                  {child.submenu?.map((subItem: any, subIndex: any) => (
                    <NestedLink
                      key={subIndex}
                      active={clickedSubMenu === subIndex}
                      to={subItem.href}>
                      {subItem.label}
                    </NestedLink>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
    </li>
  );
};

export default MainLink;
