import Button from '../Button';

interface ModalButtonInterface
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
}

/**
 * ModalButton component for rendering a button within a modal.
 *
 * @component
 * @param {ModalButtonInterface} props - The props object for the component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the button.
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onClick] - The click event handler for the button.
 * @param {boolean} [props.disabled] - Determines if the button is disabled.
 * @param {string} [props.type] - The button type, such as 'button', 'submit', or 'reset'.
 *
 * @returns {JSX.Element} The rendered ModalButton component.
 */
const ModalButton = ({
	children,
	onClick,
	disabled,
	type,
}: ModalButtonInterface): JSX.Element => {
	return (
		<Button
			type={type}
			wide
			disabled={disabled}
			className='sm:w-[calc(100%-176px)]'
			onClick={onClick}>
			{children}
		</Button>
	);
};

export default ModalButton;
