import { useCallback } from 'react';
import { useSubmit } from 'react-router-dom';
import { useAuth } from '../context/auth-context';

export const useLogout = () => {
	const submit = useSubmit();
	const { resetAuthState } = useAuth();

	const handleLogout = useCallback(() => {
		submit(null, { action: '/logout', method: 'post', replace: true });
		resetAuthState();
	}, [submit, resetAuthState]);

	return handleLogout;
};
