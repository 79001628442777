import InfoMessage from '../InfoMessage';

const Comments = ({
	title = 'Komentari, pitanja...',
	comments,
}: {
	title?: string;
	comments?: any[];
}) => {
	return (
		<div className='pks-layout-col-md'>
			<h3>{title}</h3>
			<div className='relative'>
				<ul className='pks-layout-col-xl'>
					{comments && comments?.length === 0 ? (
						<li className='pb-4'>
							<InfoMessage
								icon='info'
								message='Ne postoje komentari.'
							/>
						</li>
					) : (
						comments?.map((comment) => (
							<li
								key={comment.id}
								className='pb-4 border-b border-secondary-400'>
								<div className='mb-1'>
									{comment?.description}
								</div>
								<div className='flex flex-wrap items-center justify-between text-secondary-400'>
									<span className='mr-4'>
										{comment?.createdBy}
									</span>
									<span>{comment?.date}</span>
								</div>
							</li>
						))
					)}
				</ul>
			</div>
		</div>
	);
};

export default Comments;
