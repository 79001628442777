import { useRef } from 'react';
import { Link } from 'react-router-dom';

import LazyImage from '../../../components/shared/LazyImage';
import placeholder from '../../../assets/images/placeholders/news.png';
import placeholderCompact from '../../../assets/images/placeholders/news-square.png';
import placeholderLarge from '../../../assets/images/placeholders/news-large.png';

import { NEWS_PAGE_LINK } from '../../../constants';

import { ViewType, ViewEnum } from '../../../types';
import { NewsArticleInterface } from '../services/index.types';

/**
 * NewsListProps Interface
 *
 * Defines the props for the NewsList component.
 * @interface
 * @property {NewsArticleInterface[]} news - The array of news articles to display.
 * @property {ViewType} [view] - The view type for displaying the news, either compact, detailed, or grid.
 */
interface NewsListProps {
	news: NewsArticleInterface[];
	view?: ViewType;
}

/**
 * NewsList Component
 *
 * This component renders a list of news articles. It supports different
 * view types (compact, detailed, grid) and handles lazy loading of images.
 *
 * @param {NewsListProps} props - The props object for the component.
 * @returns {React.JSX.Element} The rendered NewsList component.
 */
const NewsList: React.FC<NewsListProps> = ({
	news,
	view = ViewEnum.Compact,
}: NewsListProps): React.JSX.Element => {
	// Determine the number of images to eagerly load based on screen width
	const eagerImgsNumRef = useRef<number | undefined>();

	if (typeof window !== 'undefined') {
		const screenWidth = window.innerWidth;
		eagerImgsNumRef.current = screenWidth < 1024 ? 0 : 5;
	}

	// Determine the main container class based on the view type
	const newsHolderClassName = `pks-layout-col-md ${
		view === ViewEnum.Compact
			? ''
			: `sm:grid sm:grid-cols-2 ${
					view === 'detailed' ? 'lg:grid-cols-1' : ''
			  }`
	}`;

	// Determine the class for each news item
	const newsItemClassName = `group ${
		view === ViewEnum.Compact
			? 'flex items-start gap-2 pb-4 border-b border-b-secondary-200'
			: ''
	}`;

	// Determine the class for the image holder of each news item
	const newsImgHolderClassName = `relative overflow-hidden rounded-lg bg-gray-300 ${
		view === ViewEnum.Compact
			? 'flex-none aspect-square'
			: 'aspect-video mb-4'
	}`;

	// Determine the class for the image element
	const newsImgClassName = `object-cover animate-fade-in ${
		view === ViewEnum.Compact
			? 'w-[60px] h-[60px]'
			: 'w-full h-full transition-transform transform group-hover:scale-105'
	}`;

	// Determine the class for the news title
	const newsTitleClassName =
		'overflow-ellipses line-clamp-2 group-hover:text-primary-300 h3';

	return (
		<ul className={newsHolderClassName}>
			{news.map((article, index) => {
				const firstGridItemClassName =
					view === 'grid' && index === 0 ? 'col-span-full' : 'w-full';

				const placeholderImage =
					view === ViewEnum.Compact
						? placeholderCompact
						: view === 'grid' && index === 0
						? placeholderLarge
						: placeholder;

				return (
					<li key={index} className={firstGridItemClassName}>
						<Link
							to={`${NEWS_PAGE_LINK}/${index + 1}`}
							title={article.title}
							className={newsItemClassName}>
							<div className={newsImgHolderClassName}>
								<LazyImage
									alt={
										article.urlToImage
											? article.title
											: 'Nedostupna slika'
									}
									src={article.urlToImage ?? placeholderImage}
									loading={
										eagerImgsNumRef.current !== undefined &&
										index < eagerImgsNumRef.current
											? 'eager'
											: 'lazy'
									}
									className={newsImgClassName}
								/>
							</div>
							<h2 className={newsTitleClassName}>
								{article.title}
							</h2>
						</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default NewsList;
