/**
 * Enum for the status of a region.
 *
 * @enum {string}
 */
export enum RegionStatusEnum {
	Active = 1,
	Inactive = 0,
}

/**
 * Represents the base details of a region.
 *
 * @interface RegionBaseInterface
 * @property {string} name - The display name for the region.
 * @property {RegionStatusEnum} status - The current status of the region.
 */
export interface RegionBaseInterface {
	name: string;
	status: RegionStatusEnum;
}

/**
 * Represents a region with its associated details, including a unique identifier.
 *
 * @interface RegionInterface
 * @extends RegionBaseInterface
 * @property {string} id - The unique identifier for the region.
 */
export interface RegionInterface extends RegionBaseInterface {
	id: number;
}
