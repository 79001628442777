import { Helmet } from "react-helmet-async"
import Logo from "../../components/shared/Logo"
import ResetPassword from "../../modules/auth/UI/ResetPassword"


const ResetPasswordPage: React.FC = () => {
    return (
        <>
            <>
			<Helmet>
				<title>Reset lozinke</title>
			</Helmet>
			<div className='min-h-screen flex items-center justify-center p-4 sm:p-8'>
				<div className='pks-layout-col-xl w-full'>
					<Logo isLink={false} className='m-auto' />
					<div className='min-h-[507px]'>
						<div className='pks-layout-col-xl max-w-[444px] m-auto p-4 bg-primary-50 rounded-lg sm:p-8'>
							<h1 className='h2'>Reset lozinke</h1>
                            <ResetPassword/>            
						</div>
					</div>
				</div>
			</div>
		</>
        </>
    )
}

export default ResetPasswordPage
