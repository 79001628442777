import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../context/auth-context';
import { getTokenDuration } from '../utils/auth';
import { useLogout } from './useLogout';

const useAuthRedirect = () => {
	const handleLogout = useLogout();
	const { token, userId, expiresAt } = useAuth();

	// Effect to handle validation and redirection
	useEffect(() => {
		if (!token || !userId || !expiresAt) {
			handleLogout();
			return;
		}

		const tokenDuration = getTokenDuration();

		// If token has expired, log out immediately
		if (tokenDuration <= 0) {
			toast.error('Access token has expired.');

			//here add refresh token
			handleLogout();
			return;
		}

		// Set a timeout to log out when the token expires
		const timeoutId = setTimeout(() => {
			handleLogout();
		}, tokenDuration);

		// Clean up timeout on component unmount
		return () => clearTimeout(timeoutId);
	}, [token, userId, expiresAt, handleLogout]);
};

export default useAuthRedirect;
