import { forwardRef, useImperativeHandle, useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import Button from '../Button';
import { CANCEL } from '../../../constants';

/**
 * Properties for the Modal component.
 *
 * @interface ModalProps
 * @property {ReactNode} children - The content to be displayed inside the modal.
 * @property {() => void} [onClose] - Optional callback function that is called when the modal is closed.
 */
interface ModalProps {
	children: ReactNode;
	onClose?: () => void;
	hasActionButton?: boolean;
}

/**
 * Interface for the methods exposed by the Modal component ref.
 *
 * @interface ModalHandleInterface
 * @property {() => void} open - Method to open the modal.
 * @property {() => void} close - Method to close the modal.
 */
export interface ModalHandleInterface {
	open: () => void;
	close: () => void;
}

/**
 * A Modal component that uses the HTML `<dialog>` element to display a modal dialog.
 *
 * @component
 * @param {ModalProps} props The properties for the modal component.
 * @param {React.Ref<ModalHandleInterface>} ref Ref object that will be used to call the `open` and `close` methods from outside the component.
 *
 * @returns {React.ReactPortal} A portal rendering the modal dialog.
 */
const Modal = forwardRef<ModalHandleInterface, ModalProps>(
	({ children, onClose, hasActionButton = true }, ref) => {
		const dialogRef = useRef<HTMLDialogElement>(null);

		// Expose the open and close methods to parent components via ref
		useImperativeHandle(ref, () => ({
			open() {
				if (dialogRef.current) {
					dialogRef.current.showModal();
				}
			},
			close() {
				if (dialogRef.current) {
					dialogRef.current.close();
				}
			},
		}));

		return createPortal(
			<dialog
				ref={dialogRef}
				className='pks-card p-4 max-h-[80vh] sm:w-full sm:max-w-[444px] rounded-lg'
				onClose={onClose}>
				<div className='pks-layout-col-md'>
					{children}
					{!hasActionButton ? (
						<div className='flex flex-col gap-4 justify-end sm:flex-row'>
							<form method='dialog'>
								<Button variant='secondary' wide>
									{CANCEL}
								</Button>
							</form>
						</div>
					) : (
						<form
							method='dialog'
							className='sm:absolute sm:w-40 sm:right-4 sm:bottom-4'>
							<Button variant='secondary' wide>
							{CANCEL}
							</Button>
						</form>
					)}
				</div>
			</dialog>,
			document.getElementById('modal') as HTMLElement
		);
	}
);

export default Modal;
