import { BasePaginationInterface as usePaginationProps } from '../types';

/**
 * Custom hook for handling pagination logic.
 *
 * @param {usePaginationProps} params - The properties for pagination.
 * @param {number} [params.currentPage=1] - The current page number. Defaults to 1.
 * @param {number} params.totalPages - The total number of pages. This property is required.
 * @param {number} [params.maxButtonCount=5] - The maximum number of pagination buttons to display. Defaults to 5.
 *
 * @returns {object} The hook returns an object containing:
 * @returns {() => number[]} generatePageNumbers - Function to generate the page numbers to display.
 *
 * @example
 * const { generatePageNumbers } = usePagination({ currentPage: 3, totalPages: 10 });
 * const pageNumbers = generatePageNumbers();
 */
const usePagination = ({
	currentPage = 1,
	totalPages,
	maxButtonCount = 5,
}: usePaginationProps): { generatePageNumbers: () => number[] } => {
	const generatePageNumbers = (): number[] => {
		const pageNumbers: number[] = [];
		const adjustedMaxButtons = maxButtonCount - 2; // account for possible ellipses
		const halfMaxButtons = Math.floor(adjustedMaxButtons / 2);

		let startPage = Math.max(1, currentPage - halfMaxButtons);
		let endPage = Math.min(totalPages, currentPage + halfMaxButtons);

		if (startPage === 1) {
			// Adjust end page if we are at the start
			endPage = Math.min(totalPages, adjustedMaxButtons + 1);
		}

		if (endPage === totalPages) {
			// Adjust start page if we are at the end
			startPage = Math.max(1, totalPages - adjustedMaxButtons);
		}

		if (startPage > 1) {
			pageNumbers.push(1);
			if (startPage > 2) {
				pageNumbers.push(-1); // -1 signifies an ellipsis
			}
		}

		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		if (endPage < totalPages) {
			if (endPage < totalPages - 1) {
				pageNumbers.push(-1); // -1 signifies an ellipsis
			}
			pageNumbers.push(totalPages);
		}

		return pageNumbers;
	};

	return {
		generatePageNumbers,
	};
};

export default usePagination;
