import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import FraudCases from '../../modules/fraudCases/UI';

function FraudCasesPage() {
	return (
		<>
			<Meta title={'Lista svih slučajeva'} />
			<PageLayout>
				<FraudCases />
			</PageLayout>
		</>
	);
}

export default FraudCasesPage;
