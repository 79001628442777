import { useEffect } from 'react';

/**
 * Custom hook to scroll to the top of the page whenever the specified dependencies change.
 *
 * @param {any[]} deps - The dependencies that trigger the scroll to top when they change.
 */
const useScrollToTop = (deps: ReadonlyArray<any>): void => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, deps);
};

export default useScrollToTop;
