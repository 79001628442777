import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { fetchStatistics } from './index.api';
import { StatisticInterface } from './index.types';

/**
 * Keys used for query identification in TanStack Query.
 */
export const QUERY_KEYS = {
	STATISTICS: 'statistics',
};

/**
 * Function that returns query options for fetching statistics data.
 *
 * @returns {UseQueryOptions<StatisticInterface[], Error>}
 * Options for the `useQuery` hook to fetch statistics data.
 */
export const getStatisticsQueryOptions = (): UseQueryOptions<
	StatisticInterface[],
	Error
> => ({
	queryKey: [QUERY_KEYS.STATISTICS],
	queryFn: fetchStatistics,
});

/**
 * Custom hook that fetch statistics data using TanStack Query.
 *
 * This hook utilizes the `useQuery` hook from TanStack Query to handle fetching, caching, and updating statistics.
 *
 * @returns {UseQueryResult<StatisticInterface[], Error>}
 * An object containing the result of the query, including:
 *   - `data`: The fetched data, which conforms to `StatisticInterface[]`.
 *   - `error`: Any error encountered during the query.
 *   - `isLoading`: A boolean indicating the loading state of the query.
 *   - `isFetching`: A boolean indicating if new data is being fetched in the background.
 *   - `isSuccess`: A boolean indicating whether the query was successful.
 */
export function useFetchStatistics(): UseQueryResult<
	StatisticInterface[],
	Error
> {
	return useQuery(getStatisticsQueryOptions());
}
