import Meta from '../components/layout/Meta';
import PageLayout from '../layouts/PageLayout';
import Regions from '../modules/regions/UI';

function RegionsPage() {
	return (
		<>
			<Meta title='Regioni' />
			<PageLayout>
				<Regions />
			</PageLayout>
		</>
	);
}

export default RegionsPage;
