import Meta from '../components/layout/Meta';

import PageLayout from '../layouts/PageLayout/';
import Statistics from '../modules/statistics/UI';
import FreudCasesPreview from '../modules/fraudCasesOld/UI/Preview';

function HomePage() {
	return (
		<>
			<Meta />
			<PageLayout title='Statistika'>
				<Statistics />
				<FreudCasesPreview count={7} />
			</PageLayout>
		</>
	);
}

export default HomePage;
