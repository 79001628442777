import {
	MainNavigationItemInterface,
	ContactIdEnum,
	ContactItemInterface,
	SocialMediaItemInterface,
	SocialMediaEnum,
} from '../types';

import { ROUTES } from '../constants/routes';

// Main Navigation Links
export const MAIN_NAVIGATION_LINKS: MainNavigationItemInterface[] = [
	{
		label: 'Opšti uslovi rada Foruma',
		url: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.ROOT.absPath,
		children: [
			{
				label: 'Savet Foruma',
				url: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_COUNCIL
					.absPath,
			},
			{
				label: 'Rad Foruma',
				url: ROUTES.DASHBOARD.ADMINISTRATION.FORUMS.FORUM_REPORTS
					.absPath,
			},
		],
	},
	/* {
		label: 'Unos institucije',
		url: ROUTES.DASHBOARD.INSTITUTIONS.INSTITUTION_ENTRY.absPath,
	}, */
	{
		label: 'Edukacija i korisne informacije',
		url: ROUTES.DASHBOARD.EDUCATION.ROOT.absPath,
		children: [
			{
				label: 'Vesti',
				url: ROUTES.DASHBOARD.EDUCATION.NEWS.absPath,
			},
		],
	},
	{
		label: 'Lista slučajeva',
		url: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath,
		children: [],
	},
];

// Footer Quick Links
export const QUICK_LINKS = [
	{
		label: 'Quick Link 1',
		url: '/',
	},
	{
		label: 'Quick Link 2',
		url: '/administracija/forum',
	},
	{
		label: 'Quick Link 3',
		url: '/administracija/forum/savet-foruma',
	},
	{
		label: 'Quick Link 4',
		url: '/administracija/forum/izveštaji-o-radu-foruma',
	},
];

// Footer Contact Links
export const CONTACT_INFO_LINKS: ContactItemInterface[] = [
	{
		id: ContactIdEnum.Address,
		label: 'Adresa<br>11000 Beograd',
		url: 'https://www.google.com/maps?q=44.78814417584898,20.498981139304636',
		ariaLabel: 'View on Google Maps',
		external: true,
	},
	{
		id: ContactIdEnum.Phone,
		label: '+1 (234) 567-890',
		url: 'tel:+1234567890',
		ariaLabel: 'Call +1 (234) 567-890',
	},
	{
		id: ContactIdEnum.Email,
		label: 'email@example.com',
		url: 'mailto:email@example.com',
		ariaLabel: 'Send an email to email@example.com',
	},
];

// Social Media Links
export const SOCIAL_MEDIA_LINKS: SocialMediaItemInterface[] = [
	{
		id: SocialMediaEnum.Facebook,
		label: 'Facebook',
		url: 'https://www.facebook.com',
	},
	{
		id: SocialMediaEnum.Twitter,
		label: 'Twitter',
		url: 'https://x.com/',
	},
	{
		id: SocialMediaEnum.LinkedIn,
		label: 'LinkedIn',
		url: 'https://www.linkedin.com/',
	},
];

// Home Page Link
export const HOME_PAGE_LINK = ROUTES.DASHBOARD.ROOT.absPath;

// Login Page Link
export const LOGIN_PAGE_LINK = ROUTES.LOGIN.ROOT.absPath;

// Login Email Verificatiion Link
export const LOGIN_EMAIL_VERIFICATION_PAGE_LINK =
	ROUTES.LOGIN.EMAIL_VERIFICATION.absPath;

// News Page Link
export const NEWS_PAGE_LINK = ROUTES.DASHBOARD.EDUCATION.NEWS.absPath;

//Fraud Cases Page Link
export const FRAUD_CASES_LINK = ROUTES.DASHBOARD.FRAUD_CASES.ROOT.absPath;

// Institution Entry Link
export const INSTITUTION_ENTRY_LINK = '';
