import Statistics from '../../modules/statistics/UI';
import FreudCasesPreview from '../../modules/fraudCasesOld/UI/Preview';

const PreviewWithStatisticsSidebar = () => {
	return (
		<div className='pks-layout-col-xl'>
			<Statistics sidebar />
			<FreudCasesPreview />
		</div>
	);
};

export default PreviewWithStatisticsSidebar;
