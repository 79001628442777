import { handleErrors } from '../../../utils';
import AuthApi from './auth.api';

import { ActionResponse } from '../../../types';
import { LoginResponseDataInterface } from './auth.types';

const api: AuthApi = new AuthApi();

class AuthStore {
	authenticateUser = async (
		email: string,
		password: string
	): Promise<ActionResponse<LoginResponseDataInterface>> => {
		try {
			const res = await api.authenticateUser(email, password);
			const resData = res.data as LoginResponseDataInterface;
			const successMessage =
				'Proceed by entering the MFA code you will receive in your email!';

			return {
				success: true,
				message: successMessage,
				data: resData,
			};
		} catch (error) {
			const { success, message } = handleErrors(error);
			return {
				success,
				message,
			};
		}
	};

	verifyCode = async (
		otpCode: string
	): Promise<ActionResponse<LoginResponseDataInterface>> => {
		try {
			const res = await api.verifyCode(otpCode);
			const resData = res.data as LoginResponseDataInterface;
			const successMessage = resData
				? 'Welcome to PKS'
				: 'Something went wrong!';

			return {
				success: true,
				message: successMessage,
				data: resData,
			};
		} catch (error) {
			const { success, message } = handleErrors(error);
			return {
				success,
				message,
			};
		}
	};

	forgetPassword = async (email: string): Promise<ActionResponse<any>> => {
		try {
			const res = await api.forgetPassword(email);
			const resData = res.data as any;
			const successMessage = resData
				? resData?.message || 'Welcome to PKS'
				: 'Something went wrong!';

			return {
				success: true,
				message: successMessage,
				data: resData,
			};
		} catch (error) {
			const { success, message } = handleErrors(error);
			return {
				success,
				message,
			};
		}
	};

	resetPassword = async (token: undefined | string, password: string, repeatedPassword: string) => {
    try {
			const res = await api.resetPassword(token, password, repeatedPassword)
			const resData = res.data as any;
			const successMessage = resData
				? resData?.message || 'Password reset successful'
				: 'Something went wrong!';

			return {
				success: true,
				message: successMessage,
				data: resData,
			};
		} catch (error) {
			const { success, message } = handleErrors(error)
      return {
        success,
        message
      };
		}
	};
}

export default AuthStore;
