import { Link } from 'react-router-dom';
import Meta from '../components/layout/Meta';
import Button from '../components/shared/Button';
import { HOME_PAGE_LINK } from '../constants';

function UnauthorizedPage() {
	return (
		<>
			<Meta title='Unauthorized' />
			<div className='pks-container flex flex-col gap-8 pt-8 pb-8 md:pt-12 md:pb-12 lg:flex-row lg:pt-40 lg:pb-40 lg:min-h-screen'>
				<main className='lg:flex-1'>
					<div className='pks-layout-col-xl max-w-xs'>
						<div className='pks-layout-col'>
							<h1 className='text-8xl font-bold'>401</h1>
							<h2 className='text-2xl font-bold'>
								Neovlašćen pristup
							</h2>
							<p>Nemate dozvolu da pristupite ovoj stranici.</p>
						</div>
						<Link to={HOME_PAGE_LINK} replace={true}>
							<Button>Početna stranica</Button>
						</Link>
					</div>
				</main>
			</div>
		</>
	);
}

export default UnauthorizedPage;
