import {
	useQuery,
	UseQueryOptions,
	UseQueryResult,
	keepPreviousData,
} from '@tanstack/react-query';

import { fetchLatestFraudCases, fetchFraudCases } from './index.api';
import { FraudCaseInterface, FraudCasePaginatedInterface } from './index.types';

/**
 * Keys used for query identification in TanStack Query.
 */
export const QUERY_KEYS = {
	LATEST_FRAUD_CASES: 'latest-fraud-cases',
	FRAUD_CASES: 'fraud-cases',
};

/**
 * Function that returns query options for fetching latest fraud case data.
 *
 * @returns {UseQueryOptions<FraudCaseInterface[], Error>}
 * - Options for the `useQuery` hook to fetch latest fraud case data.
 */
export const getLatestFraudCasesQueryOptions = (): UseQueryOptions<
	FraudCaseInterface[],
	Error
> => ({
	queryKey: [QUERY_KEYS.LATEST_FRAUD_CASES],
	queryFn: fetchLatestFraudCases,
});

/**
 * Custom hook that fetches latest fraud cases using TanStack Query.
 *
 * This hook utilizes the `useQuery` hook from TanStack Query to handle fetching,
 * caching, and updating the latest fraud case data.
 *
 * @returns {UseQueryResult<FraudCaseInterface[], Error>}
 * * An object containing the result of the query, including:
 *   - `data`: The fetched data, which conforms to `FraudCasePaginatedInterface`.
 *   - `error`: Any error encountered during the query.
 *   - `isLoading`: A boolean indicating the loading state of the query.
 *   - `isFetching`: A boolean indicating if new data is being fetched in the background.
 *   - `isSuccess`: A boolean indicating whether the query was successful.
 */
export function useFetchLatestFraudCases(): UseQueryResult<
	FraudCaseInterface[],
	Error
> {
	return useQuery(getLatestFraudCasesQueryOptions());
}

/**
 * Function that returns query options for fetching latest fraud case data.
 *
 * @returns {UseQueryOptions<FraudCaseInterface[], Error>}
 * Options for the `useQuery` hook to fetch latest fraud case data.
 */
export const getFraudCasesQueryOptions = (
	page: number,
	limit: number,
	category?: string
): UseQueryOptions<FraudCasePaginatedInterface, Error> => ({
	queryKey: [QUERY_KEYS.FRAUD_CASES, { page, limit, category }],
	queryFn: () => fetchFraudCases(page, limit, category),
	placeholderData: keepPreviousData,
});

/**
 * Custom hook that fetches paginated and filtered fraud cases using TanStack Query.
 *
 * This hook utilizes the `useQuery` hook from TanStack Query to handle fetching,
 * caching, and updating fraud case data. It accepts pagination and optional category
 * parameters to allow for dynamic querying based on the user's needs.
 *
 * @param {number} page - The current page number for pagination.
 * @param {number} limit - The number of items to be retrieved per page.
 * @param {string} [category] - Optional category filter to narrow down the fraud cases.
 *
 * @returns {UseQueryResult<FraudCasePaginatedInterface, Error>} -
 * An object containing the result of the query, including:
 *   - `data`: The fetched data, which conforms to `FraudCasePaginatedInterface`.
 *   - `error`: Any error encountered during the query.
 *   - `isLoading`: A boolean indicating the loading state of the query.
 *   - `isFetching`: A boolean indicating if new data is being fetched in the background.
 *   - `isSuccess`: A boolean indicating whether the query was successful.
 */
export function useFetchFraudCases(
	page: number,
	limit: number,
	category?: string
): UseQueryResult<FraudCasePaginatedInterface, Error> {
	return useQuery(getFraudCasesQueryOptions(page, limit, category));
}
