import { useLocation, Link, } from 'react-router-dom';
import { getNormalizedPathname } from '../../../utils';
import { NestedLinkProps } from '../../../types';


const NestedLink = ({ to, children, onToggle, active }:NestedLinkProps) => {
	const { pathname } = useLocation();
	const isCurrActive = getNormalizedPathname(pathname) === to;

	const linkClassName = `lg:bg-white bg-primary-100 w-full lg:w-fit px-4 py-2 lg:px-0 text-sm hover:text-primary-300 inline-block ${
		isCurrActive ? 'text-primary-300 pointer-events-none' : ''
	}`;

	return (
		<li onClick={onToggle} className={`flex items-center `}>
			<Link to={to} className={linkClassName}>
				{children}
			</Link>
		</li>
	);
};

export default NestedLink;