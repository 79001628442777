import { createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './constants';

/* Layouts */
import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';
import LoginLayout from './layouts/LoginLayout';

/* Pages */
import HomePage from './pages/Home';
/* import FraudCasesPage from './pages/FraudCases'; */
/* import FraudCaseDetailsPage from './pages/FraudCaseDetails'; */
/* import FraudCaseNewPage from './pages/FraudCaseNew'; */
/* import GeneralForumTermsPage from './pages/GeneralForumTerms';
import ForumReportsPage from './pages/ForumReports';
import ForumCouncilPage from './pages/ForumCouncil';
import EducationReportsPage from './pages/EducationReports';
import EducationDocumentEntryPage from './pages/EducationDocumentEntry';
import EducationNewsPage from './pages/EducationNews';
import EducationNewsDetailsPage from './pages/EducationNewsDetails';
import UserEntryPage from './pages/UserEntry';
import ForumTopicsPage from './pages/ForumTopics'; */
import UnauthorizedPage from './pages/Unauthorized';
import ErrorPage from './pages/Error';

/* Auth */
import LoginPage from './pages/auth/Login';
import EmailVerificationPage from './pages/auth/EmailVerification';
import LogoutPage from './pages/auth/Logout';
/* Administration */
import ForumMembersPage from './pages/forumMembers';
import ForumMemberFormPage from './pages/forumMembers/ForumMemberForm';
import OrganizationsPage from './pages/organizations';
import OrganizationFormPage from './pages/organizations/OrganizationForm';
import RegionsPage from './pages/Regions';
import RolesPage from './pages/roles';
/* Fraud Cases */
import FraudCasesPage from './pages/fraudCases';
import FraudCaseFormPage from './pages/fraudCases/FraudCaseForm';
import FraudCaseSinglePage from './pages/fraudCases/FraudCaseSingle';

/* Fraud Cases */

/* Services (Loaders and Actions) */
/* import { loader as fraudCasesLoader } from './modules/fraudCasesOld/services/index.loader'; */
/* import { loader as educationReportsLoader } from './modules/educationReports/services/index.loader';
import { loader as newsLoader } from './modules/news/services/index.loader'; */

/* Auth */
import { action as logoutAction } from './pages/auth/Logout';
/* Administration */
import { loader as ForumMembersPageLoader } from './modules/forumMembers/UI/index';
import { loader as forumMemberFormLoader } from './modules/forumMembers/UI/ForumMembersForm';
import { loader as organizationsLoader } from './modules/organizations/UI/index';
import { loader as organizationFormLoader } from './modules/organizations/UI/OrganizationForm';
import { loader as regionsLoader } from './modules/regions/UI/index';
import { loader as rolesLoader } from './modules/roles/UI/index';
/* Fraud Cases */
import { loader as fraudCasesLoader } from './modules/fraudCases/UI/index';
import { loader as fraudCaseLoader } from './modules/fraudCases/UI/FraudCaseSingle';

/* Components */
import ProtectedRoute from './components/ProtectedRoute';
import { RoleEnum } from './types';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPassword';

const router = createBrowserRouter([
	/* Auth */
	{
		element: <MainLayout />,
		errorElement: <ErrorPage />,
		children: [
			/* Auth */
			{
				element: <AuthLayout />,
				path: ROUTES.DASHBOARD.ROOT.relPath,
				children: [
					/* Home */
					{
						index: true,
						element: <HomePage />,
					},

					/**
					 * Administration
					 */
					{
						path: ROUTES.DASHBOARD.ADMINISTRATION.ROOT.relPath,
						children: [
							// Forum
							{
								// path: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.ROOT
								// 	.relPath,
								children: [
									{
										index: true,
										element: <ForumMembersPage />,
										loader: ForumMembersPageLoader,
									},
									{
										path: ROUTES.DASHBOARD.ADMINISTRATION
											.FORUM.FORUM_MEMBER_ADD.relPath,
										element: <ForumMemberFormPage />,
									},
									{
										path: ROUTES.DASHBOARD.ADMINISTRATION
											.FORUM.FORUM_MEMBER_EDIT.relPath,
										element: <ForumMemberFormPage />,
										loader: forumMemberFormLoader,
									},
								],
							},

							// Organizations
							{
								path: ROUTES.DASHBOARD.ADMINISTRATION
									.ORGANIZATIONS.ROOT.relPath,
								children: [
									{
										index: true,
										element: (
											<ProtectedRoute
												component={OrganizationsPage}
												roles={[RoleEnum.Admin]}
											/>
										),
										loader: organizationsLoader,
									},
									{
										path: ROUTES.DASHBOARD.ADMINISTRATION
											.ORGANIZATIONS.ORGANIZATION_ADD
											.relPath,
										element: (
											<ProtectedRoute
												component={OrganizationFormPage}
												roles={[RoleEnum.Admin]}
											/>
										),
									},
									{
										path: ROUTES.DASHBOARD.ADMINISTRATION
											.ORGANIZATIONS.ORGANIZATION_EDIT
											.relPath,
										element: (
											<ProtectedRoute
												component={OrganizationFormPage}
												roles={[RoleEnum.Admin]}
											/>
										),
										loader: organizationFormLoader,
									},
								],
							},

							// Regions
							{
								path: ROUTES.DASHBOARD.ADMINISTRATION.REGIONS
									.ROOT.relPath,
								element: <RegionsPage />,
								loader: regionsLoader,
							},

							//Role
							{
								path: ROUTES.DASHBOARD.ADMINISTRATION.ROLES.ROOT
									.relPath,
								element: <RolesPage />,
								loader: rolesLoader,
							},
						],
						// children: [
						// 	{
						// 		path: ROUTES.DASHBOARD.ADMINISTRATION.FORUM.ROOT.relPath,
						// 		children: [
						// 			{
						// 				index: true,
						// 				element: <GeneralForumTermsPage />,
						// 			},
						// 			{
						// 				path: ROUTES.DASHBOARD.ADMINISTRATION
						// 					.FORUM.FORUM_COUNCIL.relPath,
						// 				element: <ForumCouncilPage />,
						// 				loader: forumCouncilMembersLoader,
						// 			},
						// 			{
						// 				path: ROUTES.DASHBOARD.ADMINISTRATION
						// 					.FORUM.FORUM_REPORTS.relPath,
						// 				element: <ForumReportsPage />,
						// 				loader: forumReportsLoader,
						// 			},
						// 		],
						// 	},
						// ],
					},

					/**
					 * Fraud Cases
					 */
					{
						path: ROUTES.DASHBOARD.FRAUD_CASES.ROOT.relPath,
						children: [
							{
								index: true,
								element: <FraudCasesPage />,
								loader: fraudCasesLoader,
							},
							{
								path: ROUTES.DASHBOARD.FRAUD_CASES
									.FRAUD_CASE_ADD.relPath,
								element: <FraudCaseFormPage />,
							},
							/* {
								path: ROUTES.DASHBOARD.FRAUD_CASES
									.FRAUD_CASE_EDIT.relPath,
								element: <FraudCaseFormPage />,
								loader: fraudCaseLoader,
							}, */
							{
								path: ROUTES.DASHBOARD.FRAUD_CASES
									.FRAUD_CASE_DETAILS.relPath,
								element: <FraudCaseSinglePage />,
								loader: fraudCaseLoader,
							},
						],
					},
					/* {
								path: ROUTES.DASHBOARD.FRAUD_CASES_OLD
									.FRAUD_CASE_DETAILS.relPath,
								element: <FraudCaseDetailsPage />,
							},
						],
					},

					/* Fraud Cases OLD*/
					/* {
						path: ROUTES.DASHBOARD.FRAUD_CASES_OLD.ROOT.relPath,
						children: [
							{
								index: true,
								element: <FraudCasesPage />,
								loader: fraudCasesLoader,
							},
							{
								path: ROUTES.DASHBOARD.FRAUD_CASES_OLD
									.FRAUD_CASE_DETAILS.relPath,
								element: <FraudCaseDetailsPage />,
							},
							{
								path: ROUTES.DASHBOARD.FRAUD_CASES_OLD
									.FRAUD_CASE_NEW.relPath,
								element: <FraudCaseNewPage />,
							},
						],
					}, */

					/* Education */
					/* {
						path: ROUTES.DASHBOARD.EDUCATION.ROOT.relPath,
						children: [
							{
								index: true,
								element: <EducationReportsPage />,
								loader: educationReportsLoader,
							},
							{
								path: ROUTES.DASHBOARD.EDUCATION.ADD_DOCUMENT
									.relPath,
								element: <EducationDocumentEntryPage />,
							},
							{
								path: ROUTES.DASHBOARD.EDUCATION.NEWS.relPath,
								children: [
									{
										index: true,
										element: <EducationNewsPage />,
										loader: newsLoader,
									},
									{
										path: ROUTES.DASHBOARD.EDUCATION
											.NEWS_DETAILS.relPath,
										element: <EducationNewsDetailsPage />,
									},
								],
							},
						],
					}, */

					/* Forum */
					/* {
						path: ROUTES.DASHBOARD.FORUM.ROOT.relPath,
						children: [
							{
								index: true,
								element: <ForumTopicsPage />,
							},
						],
					}, */

					/* Admin-PKS */
					/* {
						path: ROUTES.DASHBOARD.ADMIN.ROOT.relPath,
						children: [
							{
								path: ROUTES.DASHBOARD.ADMIN.USER_ENTRY.relPath,
								element: (
									<ProtectedRoute
										component={UserEntryPage}
										roles={[RoleEnum.Admin]}
									/>
								),
							},
						],
					}, */

					/* Unauthorized */ {
						element: <UnauthorizedPage />,
						path: ROUTES.DASHBOARD.UNAUTHORIZED.relPath,
					},

					/* Logout */
					{
						element: <LogoutPage />,
						path: '/logout',
						action: logoutAction,
					},
				],
			},			
			{
				element: <ResetPasswordPage/>,
				path: ROUTES.RESET.ROOT.absPath,
			},

			/* Auth */
			{
				element: <LoginLayout />,
				path: ROUTES.LOGIN.ROOT.absPath,
				children: [
					{
						index: true,
						element: <LoginPage />,
					},
					{
						path: ROUTES.LOGIN.EMAIL_VERIFICATION.relPath,
						element: <EmailVerificationPage />,
						/* action: emailVerificationAction, */
					},
					/* Logout */
					{
						path: ROUTES.LOGIN.FORGOT_PASSWORD.relPath,
						element: <ForgotPasswordPage />,
					},
					
				],
			},
		],
	},
]);

export default router;
