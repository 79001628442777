// src/components/ProtectedRoute/index.tsx
import withRole from '../hocs/withRole'; // Adjust the import based on your project structure
import { RoleEnum } from '../../types';

// Defining the props interface for ProtectedRoute component
interface ProtectedRouteProps {
	component: React.FC;
	roles: RoleEnum[];
}

/**
 * A component that wraps another component with role-based access control.
 *
 * @param {ProtectedRouteProps} props - The props for the component.
 * @param {React.FC} props.component - The component to render if access is allowed.
 * @param {RoleEnum[]} props.roles - The roles allowed to access the component.
 *
 * @returns {React.JSX.Element} The wrapped component with access control applied.
 */
const ProtectedRoute = ({
	component: Component,
	roles,
}: ProtectedRouteProps): React.JSX.Element => {
	// Wrapping the passed component with role-based access control using withRole HOC
	const WrappedComponent = withRole(Component, roles);

	// Rendering the WrappedComponent which will either be the component or a fallback based on roles
	return <WrappedComponent />;
};

export default ProtectedRoute;
