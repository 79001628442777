import Icon from '../Icon';

import { SOCIAL_MEDIA_LINKS } from '../../../constants';
import { SocialMediaItemInterface } from '../../../types';

interface SocialMediaProps {
	data?: SocialMediaItemInterface[];
}

const SocialMedia: React.FC<SocialMediaProps> = ({
	data = SOCIAL_MEDIA_LINKS,
}) => {
	return (
		<ul className='flex gap-8'>
			{data.map((item) => (
				<li key={item.id}>
					<a
						href={item.url}
						target='_blank'
						rel='noopener noreferrer'
						aria-label={item.label}>
						<Icon
							name={item.id}
							className={'hover:text-primary-300'}
						/>
					</a>
				</li>
			))}
		</ul>
	);
};

export default SocialMedia;
