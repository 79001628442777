import { Outlet } from 'react-router-dom';

import PageLoader from '../PageLayout/Loader';
import SidebarLayout from '../SidebarLayout';

import useAuthRedirect from '../../hooks/useAuthRedirect';

/**
 * Props interface for AuthContentWithRedirect component.
 * @property {boolean} [isAuth] - Indicates whether the user is authenticated.
 */
interface AuthContentWithRedirectProps {
	isAuth?: boolean;
}

/**
 * AuthContentWithRedirect component conditionally renders its children based on
 * the user's authentication status and handles automatic redirection.
 *
 * @param {AuthOutletWithRedirectProps} props - The props for the component.
 * @param {boolean} [props.isAuth=false] - Indicates if the user is authenticated. Defaults to false.
 */
const AuthContentWithRedirect = ({
	isAuth = false,
}: AuthContentWithRedirectProps) => {
	// Invoke the useAuthRedirect hook to manage redirection based on authentication status
	useAuthRedirect();

	// If the user is not authenticated, render nothing
	if (!isAuth) {
		return null;
	}

	// If authenticated, render the main content area and sidebar
	return (
		<>
			<main className='lg:flex-1 relative'>
				<Outlet />
				<PageLoader />
			</main>
			<SidebarLayout />
		</>
	);
};

export default AuthContentWithRedirect;
