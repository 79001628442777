import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
	useCallback,
} from 'react';

import { getAuthStateFromLocalStorage } from '../utils';
import { UserInterface } from '../types';

// Define the shape of the context value
interface AuthStateInterface {
	token?: string;
	userId?: string;
	expiresAt?: string;
	user?: UserInterface;
	isAuth?: boolean | undefined;
}

interface AuthContextInterface extends AuthStateInterface {
	resetAuthState: () => void;
	updateAuthState: () => void;
}

// Default initial state
const DEFAULT_AUTH_STATE: AuthStateInterface = {
	token: undefined,
	userId: undefined,
	expiresAt: undefined,
	user: undefined,
	isAuth: undefined,
};

// Initialize context with default values
const AuthContext = createContext<AuthContextInterface>({
	...DEFAULT_AUTH_STATE,
	resetAuthState: () => {
		console.warn('resetAuthState function not initialized');
	},
	updateAuthState: () => {
		console.warn('setAuthState function not initialized');
	},
});

interface AuthProviderProps {
	children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [authState, setAuthState] = useState<AuthStateInterface>(() => {
		const { token, userId, expiresAt, user } =
			getAuthStateFromLocalStorage();
		const isAuth = token && userId && expiresAt && user;
		return { token, userId, expiresAt, user, isAuth };
	});

	const { isAuth } = authState;
	const isInvalid = !isAuth;

	const resetAuthState = useCallback(() => {
		setAuthState(DEFAULT_AUTH_STATE);
		localStorage.removeItem('auth');
	}, []);

	const updateAuthState = useCallback(() => {
		const {
			token: lsToken,
			userId: lsUserId,
			expiresAt: lsExpiration,
			user: lsUser,
		} = getAuthStateFromLocalStorage();

		if (lsToken && lsUserId && lsExpiration) {
			setAuthState((prevState) => ({
				...prevState,
				token: lsToken,
				userId: lsUserId,
				user: lsUser,
				expiresAt: lsExpiration,
				isAuth: true,
			}));
		}
	}, []);

	// Handle storage changes
	useEffect(() => {
		const handleStorageChange = (event: StorageEvent) => {
			// Ignore changes if `isInvalid` is true
			if (isInvalid) return;

			// Check if the change is related to the `auth` key
			if (event.storageArea === localStorage && event.key === 'auth') {
				if (event.newValue === null) {
					// The `auth` key was deleted
					console.warn(
						'Auth key removed from localStorage. Logging out user.'
					);

					resetAuthState(); // Log out the user
				} else {
					try {
						// Parse the new auth state from localStorage
						const newAuthState = event.newValue
							? JSON.parse(event.newValue)
							: null;

						if (newAuthState) {
							// Log a warning for unauthorized changes
							console.warn(
								'Unauthorized change detected in localStorage auth key. Ignoring changes.'
							);

							// Optional: Revert unauthorized changes by resetting the `auth` key in localStorage
							localStorage.setItem(
								'auth',
								JSON.stringify(authState)
							);
						}
					} catch (error) {
						console.error(
							'Failed to parse updated auth data from localStorage',
							error
						);
					}
				}
			}
		};

		// Add event listener to handle changes
		window.addEventListener('storage', handleStorageChange);

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [isInvalid, authState, resetAuthState]);

	return (
		<AuthContext.Provider
			value={{ ...authState, updateAuthState, resetAuthState }}>
			{children}
		</AuthContext.Provider>
	);
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
