import { useCallback, useEffect, useRef, useState } from 'react';
import { LoaderFunctionArgs } from 'react-router-dom';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { showToast } from '../../../utils';

import PageTitleWithActions from '../../../layouts/PageLayout/TitleWithActions';
import Pagination from '../../../components/shared/Pagination';
import Button from '../../../components/shared/Button';
import InfoMessage from '../../../components/shared/InfoMessage';
import Modal, { ModalHandleInterface } from '../../../components/shared/Modal';
import RolesList from './RolesList';
import RoleForm from './RoleForm';
import PermissionsList from './PermissionsList';

import { useInfiniteScrollSentinel } from '../../../hooks/useInfiniteScroll';
import {
	InfinitePermissionsResponseDataInterface,
	RoleAddInterface,
} from '../services/role.types';
import RolesStore from '../services/roles.store';

const rolesStore: RolesStore = new RolesStore();

interface RolesModalInterface {
	role: RoleAddInterface | undefined;
	isOpen: boolean;
}

const INIT_FORM_OPTIONS: RolesModalInterface = {
	role: undefined,
	isOpen: false,
};

const Roles = () => {
	const [currentPage, setCurrentPage] = useState(1);

	/* Roles */
	const { data: rolesData } = rolesStore.useGetRoles(currentPage);
	const roles = rolesData?._embedded.roles;
	const totalPages = rolesData?._page_count || 1;
	const [selectedRole, setSelectedRole] = useState<number | undefined>(
		roles && roles.length > 0 ? roles[0].id : undefined
	);

	/* Permissions per role */
	const { data: permissionsPerRoleData } =
		rolesStore.useGetPermissionsPerRole(selectedRole);
	const [permissionsPerRole, setPermissionPerRole] = useState<number[]>([]);

	/* Permissions */
	const {
		data: permissionsData,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	}: UseInfiniteQueryResult<
		InfinitePermissionsResponseDataInterface,
		Error
	> = rolesStore.useGetPermissions(1);

	const permissions = permissionsData?.pages.flatMap(
		(page) => page?._embedded?.permissions || []
	);

	/* Infinite scroll management */
	const permissionsSentinelRef = useRef<HTMLDivElement>(null);

	/* Modal management */
	const dialogRef = useRef<ModalHandleInterface>(null);
	const [modalOptions, setModalOptions] =
		useState<RolesModalInterface>(INIT_FORM_OPTIONS);

	/**
	 * Effects
	 */

	// Infinite Scroll
	useInfiniteScrollSentinel({
		sentinelRef: permissionsSentinelRef,
		hasNextPage,
		loadMore: fetchNextPage,
		isLoading: isFetchingNextPage,
	});

	// Set initial selected role
	useEffect(() => {
		if (selectedRole) return;
		if (roles && roles.length > 0 && selectedRole === undefined) {
			setSelectedRole(roles[0].id);
		}
	}, [roles, selectedRole]);

	// Toggle Modal
	useEffect(() => {
		modalOptions.isOpen
			? dialogRef.current?.open()
			: dialogRef.current?.close();
	}, [modalOptions.isOpen]);

	// Initialize `permissionsPerRole` state when `permissionsPerRoleData` or `selectedRole` changes
	useEffect(() => {
		if (permissionsPerRoleData?.permissions) {
			setPermissionPerRole(
				permissionsPerRoleData.permissions.map(
					(permission: any) => permission.id
				)
			);
		}
	}, [permissionsPerRoleData, selectedRole]);

	/**
	 *  Handlers
	 */

	// Update modal form options
	const handleUpdateFormOptions = (role?: RoleAddInterface | null): void => {
		setModalOptions({
			isOpen: true,
			role: role ?? undefined,
		});
	};

	// Handles modal close
	const handleCloseModal = useCallback(() => {
		setModalOptions(INIT_FORM_OPTIONS);
	}, []);

	// Handles page change
	const handlePageChange = (newPage: number) => {
		setCurrentPage(newPage);
	};

	// Handles selected role
	const handleSelectedRole = (id: number) => {
		setSelectedRole(id);
	};

	// Handles permission changes for selected role
	const handleCheckedItemsChange = useCallback(
		async (updatedPermissions: number[]) => {
			if (!selectedRole) return;

			// Maintain a backup of the previous state
			const previousPermissions = [...permissionsPerRole];

			// Optimistically update the state
			setPermissionPerRole(updatedPermissions);

			// Perform the request to update the role permissions
			const { success, message } = await rolesStore.updateRolePermissions(
				selectedRole,
				updatedPermissions
			);

			if (!success) {
				setPermissionPerRole(previousPermissions);
				showToast(message, success);
			}
		},
		[selectedRole, permissionsPerRole]
	);

	return (
		<>
			<div className='flex flex-col lg:flex-row gap-8'>
				{/* Roles */}
				<div className='flex-1 h-full'>
					<div className='pks-layout-col-md h-full'>
						<PageTitleWithActions title='Role'>
							<Button onClick={() => handleUpdateFormOptions()}>
								Dodaj rolu
							</Button>
						</PageTitleWithActions>
						<RolesList
							roles={roles}
							selected={selectedRole}
							onClick={handleSelectedRole}
							onUpdate={handleUpdateFormOptions}
						/>
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
				{/* Permissions */}
				<div className='flex-1'>
					<div className='pks-layout-col-md'>
						<PageTitleWithActions title='Permisije'>
							<Button className='opacity-0'>Placeholder</Button>
						</PageTitleWithActions>
						{permissionsData ? (
							<div>
								{permissions && permissions.length > 0 && (
									<div className='pks-layout-col-md'>
										<PermissionsList
											permissions={permissions}
											permissionsPerRole={
												permissionsPerRole
											}
											onCheckedItemsChange={
												handleCheckedItemsChange
											}
										/>
										{isFetchingNextPage && (
											<InfoMessage
												icon='info'
												message='Loading Data ...'
											/>
										)}
										<div
											ref={permissionsSentinelRef}
											style={{ height: '1px' }}
										/>
									</div>
								)}
							</div>
						) : (
							<InfoMessage
								icon='info'
								message={
									!isFetching
										? 'Nema dostupnih dozvola'
										: 'Loading Data ...'
								}
							/>
						)}
					</div>
				</div>
			</div>
			{/* Modal for Add / Edit Role Form */}
			{modalOptions.isOpen && (
				<Modal ref={dialogRef} onClose={handleCloseModal}>
					<RoleForm
						role={modalOptions.role}
						onClose={handleCloseModal}
					/>
				</Modal>
			)}
		</>
	);
};

export default Roles;

/**
 * Loader function to prefetch roles.
 */
export async function loader({ request }: LoaderFunctionArgs) {
	const { searchParams } = new URL(request.url);
	const page = Number(searchParams.get('page')) || 1;

	await rolesStore.preloadRoles(page);
	return null;
}
