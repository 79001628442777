interface LabelProps {
	id?: string;
	label: string;
	onClick?: () => void;
}

const Label: React.FC<LabelProps> = ({ id, label, onClick }) => {
	return id ? (
		<label htmlFor={id} className='block mb-1 hover:cursor-pointer'>
			{label}
		</label>
	) : (
		<span className='block mb-1' onClick={onClick}>
			{label}
		</span>
	);
};

export default Label;
