import http from '../../../utils/http';
import { NewsArticleInterface, NewsPaginationInterface } from './index.types';

const API_KEY = process.env.REACT_APP_NEWS_API_KEY;

// Default URL parameters for news API requests
const URL_PARAMS = {
	LATEST_NEWS: {
		limit: 4,
	},
	NEWS: {
		page: 1,
		limit: 5,
		showLimitInUrl: false,
	},
};

// Class to handle API requests for news data
class NewsApi {
	// Fetch latest news
	fetchLatestNews = () => {
		return http.get(
			`https://newsapi.org/v2/top-headlines?country=us&pageSize=${URL_PARAMS.LATEST_NEWS.limit}&apiKey=${API_KEY}`
		);
	};

	// Fetch paginated news
	fetchNews = (page: number, limit: number) => {
		return http.get(
			`https://newsapi.org/v2/top-headlines?country=us&page=${page}&pageSize=${limit}&apiKey=${API_KEY}`
		);
	};
}

/** API Instance */
const api = new NewsApi();

/**
 * Fetch the latest news articles.
 * @returns {Promise<NewsArticleInterface[]>} - The latest news articles.
 */
async function fetchLatestNews(): Promise<NewsArticleInterface[]> {
	const response = await api.fetchLatestNews();
	const articles = response.data.articles;
	return articles;
}

/**
 * Fetch paginated news articles.
 * @param {number} [page=URL_PARAMS.NEWS.page] - The page number to fetch.
 * @param {number} [limit=URL_PARAMS.NEWS.limit] - The number of articles per page.
 * @param {boolean} [showLimitInUrl=URL_PARAMS.NEWS.showLimitInUrl] - Whether to include the limit in the URL.
 * @returns {Promise<NewsPaginationInterface>} - The paginated news articles and total count.
 */
async function fetchNews(
	page: number = URL_PARAMS.NEWS.page,
	limit: number = URL_PARAMS.NEWS.page,
	showLimitInUrl: boolean = URL_PARAMS.NEWS.showLimitInUrl
): Promise<NewsPaginationInterface> {
	const response = await api.fetchNews(page, limit);
	const articles = response.data.articles;
	const total = response.data.totalResults;
	return { articles, total, showLimitInUrl };
}

/** Exported Functions and Constants */
export { URL_PARAMS, fetchLatestNews, fetchNews };
export default NewsApi;
