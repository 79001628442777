import http from '../../../utils/http';

class AuthApi {
	// Authenticate user
	async authenticateUser(username: string, password: string) {
		return await http.post(`/login`, { username, password });
	}

	// Verify user
	async verifyCode(mfa: string) {
		return await http.post(`/mfa`, { mfa });
	}

	async forgetPassword(email: string) {
		return await http.post(`/users/forgotpasswordrequest`, {
			email,
		});
	}
	async resetPassword(token:undefined | string, password: string, repeatedPassword: string) {
		return await http.post("/users/resetpasswordwithtoken", {
			token,
			password,
			repeated_password: repeatedPassword
		})
	}
}

export default AuthApi;
